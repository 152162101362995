import React, { useEffect, useRef, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import play from "../assets/playstore.svg";
import apple from "../assets/apple_store.jpg";
import test from "../assets/hotel2.jpg";
import one from "../assets/666 copy.png";
import { getClassification } from "../services/category";
import { getLocation } from "../services/location";
import { search } from "../services/home";
import { useNavigate } from "react-router-dom";
import { Button } from "react-scroll";
import { IoMdClose } from "react-icons/io";

const Home = () => {
  const navigate = useNavigate();
  const [showLocationsDropdown, setShowLocationsDropdown] = useState(false);
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showError, setShowError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [found, setFound] = useState("");
  const [content1, setContent1] = useState(false);

  const divRef = useRef(null);
  useEffect(() => {
    getClassification().then((res) => {
      setCategories(res.data);
    });
    getLocation().then((res) => {
      setLocations(res.data);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowCategoriesDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowCategoriesDropdown(false);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleLocationsClick = () => {
    setShowLocationsDropdown(!showLocationsDropdown);
    setShowCategoriesDropdown(false);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCategoriesClick = () => {
    setShowCategoriesDropdown(!showCategoriesDropdown);
    setShowLocationsDropdown(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!selectedLocation || !selectedCategory) {
      // If location or category is not selected, show error
      setShowError(true);
    } else {
      // If both location and category are selected, perform search
      setShowError(false);
      search(selectedCategory.id, selectedLocation.id)
        .then((res) => {
          // Clean up the name
          const decodedName = res.data.name
            .replace(/ /g, "-")
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove unwanted symbols
          // Construct the URL and navigate
          navigate(
            `/${res.data.category.name}/${res.data.location.name}/${decodedName}/onemindmarket/${res.data.id}`,
            {
              state: { id: res.data.id },
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
          if (error.response.status === 400) {
            const location = locations.filter(
              (i) => selectedLocation.id !== i.id
            );
            search(selectedCategory.id, location[0].id)
              .then((res) => {
                setFound(res.data);
                setContent1(true);
                setIsSubmitted(true);
              })
              .catch((error) => {
                console.log("error", error);
                setContent1(false);
                setIsSubmitted(true);
              });
          }
        });
    }
  };

  const buttonClicked = () => {
    const decodedName = found.name.replace(/ /g, "-");

    navigate(
      `/${found.category.name}/${found.location.name}/${decodedName}/onemindmarket/${found.id}`,
      {
        state: { id: found.id },
      }
    );
  };

  return (
    <>
      <div className="flex justify-center" id="home">
        <div className="md:px-12 max-w-screen-2xl  p-4  mt-16 mx-auto">
          <div class="flex justify-center w-[90%] md:w-[70%]  md:ml-28  md:p-6 p-4">
            <img
              src={one}
              alt="Uploaded"
              class="  md:p-0 md:h-36 md:w-96 -mt-28  md:-mt-12 w-[100%] h-44 object-cover ml-4 md:ml-8"
            />
            {/* <div class="ml-2 md:ml-2  lg:ml-8 md:p-0 p-3">
              <h1 class="md:pl-6 lg:pl-8 text-2xl md:text-4xl font-bold">
                City!
              </h1>
              <h1 class=" md:pl-6 lg:pl-8 text-2xl md:text-4xl font-bold">
                Classification!
              </h1>
              <h1 class=" md:pl-6 lg:pl-8 text-2xl md:text-4xl font-bold">
                Entrepreneur!
              </h1>
            </div> */}
          </div>

          <span className="text-gray text-sm flex justify-center">
            <h1 className=" p-2 pl-3 md:pl-4  ">
              <span className="md:flex hidden">
                Native Entrepreneurs are important; Because they can create
                employment, preserve culture and identity,
              </span>
              <h4 className=" md:ml-12 ml-0 md:flex hidden">
                generate income, address social needs, draw inspiration from
                cultural heritage.
              </h4>
            </h1>
          </span>
          <span className="text-gray text-xs flex w-full text-justify justify-center md:hidden">
            <p className=" p-2 pl-3    ">
              Native Entrepreneurs are important; Because they can create
              employment, preserve culture and identity, generate income,
              address social needs, draw inspiration from cultural heritage
            </p>
          </span>
          <div className="flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-2 justify-center">
            <div className="relative">
              <div
                className={`mt-2 md:mt-0 px-8 md:px-32 py-6 border border-lightgray hover:border-primary ${
                  !selectedLocation && showError ? "border-red text-red" : ""
                }  bg-lightgray cursor-pointer relative z-10`}
                onClick={() => {
                  setShowLocationsDropdown(!showLocationsDropdown);
                  setShowCategoriesDropdown(false);
                }}
              >
                <h2 className="flex justify-center">
                  {selectedLocation ? selectedLocation.name : "Select Location"}
                </h2>
                {showLocationsDropdown ? (
                  <FaChevronUp className="absolute right-4 top-6 cursor-pointer" />
                ) : (
                  <FaChevronDown
                    className="absolute right-4 top-6 cursor-pointer"
                    onClick={handleLocationsClick}
                  />
                )}
                {showLocationsDropdown && (
                  <div className="absolute bg-white border w-full rounded mt-10  p-2  overflow-y-auto left-0 right-0 mx-auto">
                    {locations.map((location, index) => (
                      <div
                        key={index}
                        className="flex items-center p-2 cursor-pointer hover:bg-primary text-gray  hover:text-white transition duration-300"
                        onClick={() => setSelectedLocation(location)}
                      >
                        {location.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {showCategoriesDropdown === false ? (
              <div className="relative" ref={divRef}>
                <div
                  className={`px-8 ${
                    showCategoriesDropdown === true ? "md:px-24" : "md:px-32"
                  }  py-6 bg-lightgray border border-lightgray hover:border-primary  ${
                    showCategoriesDropdown === true
                      ? " cursor-text"
                      : "cursor-pointer"
                  } relative flex ${
                    showCategoriesDropdown === true
                      ? ""
                      : "justify-center items-center"
                  }  ${
                    !selectedCategory && showError ? "border-red text-red" : ""
                  }`}
                  onClick={() => {
                    setShowCategoriesDropdown(true);
                    setShowLocationsDropdown(false);
                  }}
                >
                  {showCategoriesDropdown && (
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      className="w-full right-20 px-3 bg-lightgray  outline-none "
                    />
                  )}
                  <h3>
                    {showCategoriesDropdown === false
                      ? selectedCategory
                        ? selectedCategory.name
                        : "Select Classification"
                      : ""}
                  </h3>
                  {showCategoriesDropdown ? (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronUp className="absolute right-4 top-6 cursor-pointer" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronDown className="absolute right-4 top-6 cursor-pointer" />
                    </button>
                  )}
                </div>
                {showCategoriesDropdown && (
                  <div className="absolute bg-white border border-gray w-full h-36 rounded mt-4 p-2 max-h-40 overflow-y-auto scrollbar-custom left-0 right-0 mx-auto">
                    {filteredCategories.map((category, index) => (
                      <div
                        key={index}
                        className="flex items-center p-2 cursor-pointer hover:bg-primary text-gray hover:text-white transition duration-300"
                        onClick={() => {
                          handleCategorySelect(category);
                          setShowCategoriesDropdown(false);
                        }}
                      >
                        <div className="w-[100%] ml-3">{category.name}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div className="relative" ref={divRef}>
                <div
                  className={`px-8 ${
                    showCategoriesDropdown === true ? "md:px-24" : "md:px-32"
                  }  py-6 bg-lightgray border border-lightgray hover:border-primary  ${
                    showCategoriesDropdown === true
                      ? " cursor-text"
                      : "cursor-pointer"
                  } relative flex ${
                    showCategoriesDropdown === true
                      ? ""
                      : "justify-center items-center"
                  }${
                    !selectedCategory && showError ? "border-red text-red" : ""
                  } `}
                >
                  {showCategoriesDropdown && (
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      className="w-full right-20 px-3 bg-lightgray  outline-none "
                    />
                  )}
                  <h3>
                    {showCategoriesDropdown === false
                      ? selectedCategory
                        ? selectedCategory.name
                        : "Select Classification"
                      : ""}
                  </h3>
                  {showCategoriesDropdown ? (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronUp className="absolute right-4 top-6 cursor-pointer" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronDown className="absolute right-4 top-6 cursor-pointer" />
                    </button>
                  )}
                </div>
                {showCategoriesDropdown && (
                  <div className="absolute bg-white border border-gray w-full h-auto rounded mt-4 p-2 max-h-40 overflow-y-auto scrollbar-custom left-0 right-0 mx-auto">
                    {filteredCategories.map((category, index) => (
                      <div
                        key={index}
                        className="flex items-center p-2 cursor-pointer hover:bg-primary text-gray hover:text-white transition duration-300"
                        onClick={() => {
                          handleCategorySelect(category);
                          setShowCategoriesDropdown(false);
                        }}
                      >
                        <div className="w-[100%] ml-3">{category.name}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div
              className={`bg-primary hover:bg-black px-4 w-full md:w-auto flex text-white items-center justify-center cursor-pointer`}
              onClick={(e) => handleSearch(e)}
            >
              <span
                className={`md:hidden py-6 ${
                  showCategoriesDropdown === true ? "mt-36" : "mt-1"
                }`}
              >
                Search
              </span>
              <IoSearch className="hidden md:inline" size={32} />
            </div>
          </div>
          {isSubmitted && (
            <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto md:w-3/6 w-5/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                <div className=" flex justify-end">
                  <IoMdClose
                    className="text-black hover:cursor-pointer"
                    size={24}
                    onClick={() => {
                      setIsSubmitted(false);
                    }}
                  />
                </div>
                <p className="text-2xl flex justify-center text-center ">
                  Thank you for visiting One Mind Market!
                </p>
                <div className="flex flex-col items-center mt-4 space-y-3 md:space-y-0 md:flex-row md:space-x-3 md:justify-center">
                  {content1 === true ? (
                    <>
                      <p className="text-para text-sm text-center md:text-left">
                        The classification you searched for is located in
                      </p>
                      <button
                        className="relative text-sm text-primary  group"
                        onClick={() => {
                          buttonClicked();
                        }}
                      >
                        {found.location.name}
                        <span className=" md:flex hidden absolute left-1/2 transform -translate-x-1/2 text-ms mt-4 text-para w-24 bg-white p-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                          click here
                        </span>
                      </button>
                      <button
                        className="bg-primary text-white rounded-lg p-0.5 md:hidden text-xs"
                        onClick={() => {
                          buttonClicked();
                        }}
                      >
                        click here
                      </button>
                    </>
                  ) : (
                    <p className="text-para text-sm text-center md:text-left">
                      The classification you searched for is comming soon!
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            className={` md:flex justify-center flex-none  ${
              showCategoriesDropdown === true ? "mt-36" : "mt-12"
            }  ${showLocationsDropdown === true ? "mt-24" : "mt-12"} `}
          >
            <span className="mt-16 text-xl font-bold md:pl-0 pl-28">
              Download App
            </span>
            <a href="https://play.google.com/store/apps/details?id=com.onemindmarket ">
              <img
                src={play}
                alt="play"
                className="md:w-56 w-60 mb-4 md:mb-0 md:ml-18 ml-3 md:pl-0 pl-24 text-center"
              />
            </a>
            <a href="https://apps.apple.com/app/one-mind-market/id6504680708 ">
              <img
                src={apple}
                alt="apple"
                className="md:w-56 w-60  -mt-12 md:mb-0 md:-mt-3   md:ml-18 ml-3 md:pl-0 pl-24 text-center"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
