import api from "../api";

const baseURL = "/api/admin";

export const businessList = () => {
  return api.get(`${baseURL}/business`);
};

export const bussinessOnboard = (
  name,
  businessName,
  mobile,
  email,
  password,
  category,
  location
) => {
  return api.post(`${baseURL}/bussinessOnboard`, {
    name,
    businessName,
    mobile,
    email,
    password,
    category,
    location,
  });
};

export const dashboard = () => {
  return api.get(`${baseURL}/dashboard`);
};

export const liveStream = (embbedUrl, url, urlId) => {
  return api.post(`${baseURL}/liveStream`, {
    embbedUrl,
    url,
    urlId,
  });
};

export const newsDelete = (id) => {
  return api.delete(`${baseURL}/newsDelete/${id}`);
};

export const deleteBusiness = (id) => {
  return api.delete(`${baseURL}/businessDelete/${id}`);
};
