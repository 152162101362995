import api from "../api";

const baseUrl = "/api/news";

export const getNewses = () => {
  return api.get(baseUrl);
};

export const getNews = (id) => {
  return api.get(`${baseUrl}/${id}`);
};

export const createNews = (
  title,
  location,
  description,
  key,
  image,
  publishBy
) => {
  return api.post(baseUrl, {
    title,
    location,
    description,
    key,
    image,
    publishBy,
  });
};

export const editNews = (id, title, location, description) => {
  return api.put(`${baseUrl}/${id}`, { title, location, description });
};

export const deleteNews = (id) => {
  console.log("id", id, "id");
  return api.delete(`${baseUrl}/${id}`);
};
