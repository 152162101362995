import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./assets/One_Mind_Market_Black_Logo_with_text.png";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="text-center">
        <div className='flex justify-center mb-8'>
          <img src={logo} alt="logo" className='h-32 w-auto' />
        </div>
        <h1 className="text-7xl font-extrabold text-gray-800">404</h1>
        <p className="text-2xl text-gray-600 mt-4">Page Not Found</p>
        <p className="text-lg text-gray-500 mt-2">
          The page you are looking for doesn't exist or an error occurred.
        </p>
        <Link to="/" className="mt-8 inline-block bg-primary text-white text-lg px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
