import axios from "axios";

// export const baseURL = "http://65.2.8.35:4848/";
// export const baseURL = "http://localhost:4848/";
// export const baseURL = "http://192.168.1.11:4848/";
export const baseURL = "https://app.onemindmarket.in/"; //23-06-24 ssl expiry

// export const baseURL = "https://13.232.24.110:4848/";

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("authToken")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "authToken"
      )}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
