import React, { useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/One_Mind_Market_Black_Logo_with_text.png";
import logmob from "../../../assets/Variant_04.png";
import { useNavigate } from "react-router-dom";
import { codeVerification, resendOtp } from "../../../services/user";

const Flowotp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token || "";
  const user = location.state?.user || "";

  const [mobile, setMobile] = useState(""); //change to code or otp
  const [mobileError, setMobileError] = useState("");

  const handleRegister = (e) => {
    e.preventDefault();
    if (mobile.length !== 6) {
      setMobileError("OTP  should be 6 digits.");
      return;
    }

    codeVerification(mobile, token)
      .then((res) => {
        navigate("/createPassword", { state: { id: res.data.user.id } });
      })
      .catch((error) => {
        const { response } = error;
        console.log("error", error);
        setMobileError(response.data.message);
      });

    console.log("Registering:", {
      mobile,
    });
  };

  const handleResendOTP = () => {
    resendOtp(user.id, user.mobile).then((res) => {
      console.log("res", res.data);
    });
  };

  const isMobileValid = mobile.length === 6;
  return (
    <div className="h-full max-w-screen-2xl min-h-screen mx-auto w-full flex flex-col md:flex-row">
      <span className="w-full md:w-1/2 bg-lightgray hidden md:inline  items-center justify-center p-6 xl:py-48">
        <img src={logo} alt="" className="w-1/2 md:w-1/2  xl:w-1/2 mx-auto" />
        <h1 className="flex justify-center font-inter mt-16">
          One City! One Classification! One Entrepreneur!
        </h1>
      </span>
      <span>
        <img src={logmob} alt="logo" className="md:hidden" />
      </span>

      <div className="w-full md:w-1/2 p-6">
        <div className="w-full flex justify-between p-4 md:p-8">
          <Link to="/forgotpasswordFlow1">
            <span className="flex font-semibold mb-4 md:mb-0">
              <MdOutlineArrowBackIos className="mt-1" />
              Back
            </span>
          </Link>
        </div>

        <div className="p-4 md:p-8">
          <h1 className="text-2xl font-bold mt-4 text-center md:text-left">
            Enter OTP
          </h1>
          <div className="w-full">
            <form className="space-y-4">
              <p className="text-sm text-gray pt-3 text-center md:text-left">
                Please enter Verification code
              </p>

              <div className="mb-3">
                <label htmlFor="mobile" className="block font-medium">
                  Enter OTP
                </label>
                <input
                  type="number"
                  id="mobile"
                  className={`mt-1 p-2 border w-full ${
                    mobileError && "border-red"
                  }`}
                  value={mobile}
                  placeholder="Enter Your OTP"
                  onChange={(e) => {
                    setMobile(e.target.value);
                    if (e.target.value.length !== 6) {
                      setMobileError("OTP should be 6 digits.");
                    } else {
                      setMobileError("");
                    }
                  }}
                  required
                />
                {mobileError && (
                  <p className="text-red text-sm">{mobileError}</p>
                )}
              </div>
              <Link to="/otp">
                <button
                  type="button"
                  className={`bg-primary hover:bg-black text-white w-full py-3 mt-4 ${
                    !isMobileValid && "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={handleRegister}
                  disabled={!isMobileValid}
                >
                  Send OTP
                </button>
              </Link>
            </form>
            <div className="flex justify-between">
              <div
                className="text-center flex md:text-left mt-3"
                onClick={() => {
                  handleResendOTP();
                }}
              >
                Resend
                <span className="text-primary font-semibold pl-2">OTP</span>
              </div>
              <h1 className="text-center flex  md:text-right mt-3">
                Just remember?
                <Link to="/Login">
                  <span className="text-primary font-semibold pl-2">Login</span>
                </Link>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flowotp;
