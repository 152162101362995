import React, { useEffect, useState, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { getNewses } from "../services/news";
import dayjs from "dayjs";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const News = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  // const [newsItems, setNewsItems] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [item, setItem] = useState("");
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenNews = (item) => {
    setIsDialogOpen(true);
    setItem(item);
  };
  const [newsItems, setNewsItems] = useState([]);
  const itemsPerPage = 5;
  const newsRef = useRef(null);

  useEffect(() => {
    getNewses().then((res) => {
      setNewsItems(res.data);
    });
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    newsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(newsItems.length / itemsPerPage);

  return (
    <div id="news" ref={newsRef}>
      {location.pathname === "/" ? (
        <h2 className="flex justify-center bg-primary text-white mt-8 py-3 font-semibold">
          News
        </h2>
      ) : (
        ""
      )}
      {newsItems === null ||
      newsItems === undefined ||
      newsItems.length === 0 ? (
        <div>
          <h2 className="text-xl md:text-1xl font-semibold  flex justify-center items-center  p-4 ">
            No latest news yet.
          </h2>
        </div>
      ) : (
        <>
          <div
            className={`flex   max-w-screen-2xl  ${
              location.pathname === "/"
                ? "md:w-[70%] items-center mx-auto justify-end"
                : "md:w-full justify-between"
            } mt-4`}
          >
            {location.pathname === "/" ? (
              ""
            ) : (
              <h2 className="text-xl md:text-3xl font-semibold     ">
                Latest News
              </h2>
            )}
            <div className="flex ">
              <Button
                variant="text"
                className={`flex items-center gap-2 ${
                  activePage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                } ${activePage === 1 ? "text-para" : ""} `}
                onClick={() => handlePageChange(activePage - 1)}
                disabled={activePage === 1}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
              </Button>
              <div className="flex items-center gap-1">
                {activePage > 1 && (
                  <button
                    onClick={() => handlePageChange(activePage - 1)}
                    className="bg-white text-black px-4 py-2 rounded-lg"
                  >
                    {activePage - 1}
                  </button>
                )}

                <button
                  key={activePage}
                  onClick={() => handlePageChange(activePage)}
                  className="bg-primary text-white px-4 py-2 rounded-lg"
                >
                  {activePage}
                </button>

                {activePage < totalPages && (
                  <button
                    onClick={() => handlePageChange(activePage + 1)}
                    className="bg-white text-black px-4 py-2 rounded-lg"
                  >
                    {activePage + 1}
                  </button>
                )}
              </div>

              <Button
                variant="text"
                className={`flex items-center ${
                  activePage === totalPages
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }  gap-2 ${activePage === totalPages ? "text-para" : ""}`}
                onClick={() => handlePageChange(activePage + 1)}
                disabled={activePage === totalPages}
              >
                Next <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <div
            className={`${
              location.pathname === "/" ? "md:px-10" : ""
            }  md:flex-justify-center max-w-screen-2xl md:w-[100%] w-5/6 mt-8 mx-auto`}
          >
            {currentItems.map((item, index) => (
              <div
                key={index}
                className={`bg-lightgray w-full border border-gray ${
                  location.pathname === "/" ? " md:w-[70%] " : "w-full"
                } justify-center items-center mx-auto flex flex-col mb-4 md:flex-row h-90 md:h-[50vh]`}
              >
                <div className="w-full md:w-[50%] mt-4 md:mt-0 md:mr-4">
                  <img
                    src={item.image}
                    alt="news image"
                    className="w-full max-h-full p-6 object-cover"
                  />
                </div>
                <div className="w-full md:w-[70%] px-4 py-5 overflow-hidden">
                  <h3 className="md:text-xl text-sm font-semibold md:pl-4 font-sans mb-3 text-primary font-inter p-2">
                    {item.title}
                    <span className="text-black font-inter p-2">
                      {item.location} {dayjs(item.time).format("DD/MM/YYYY")}
                    </span>
                  </h3>
                  <p className="md:pl-4 mb-4 font-sans text-para h-36 overflow-hidden font-inter font-medium text-center md:text-justify">
                    {item.description}
                  </p>
                  <div className="flex justify-end">
                    <button
                      className="font-semibold font-sans flex text-black p-1 text-sm rounded-full cursor-pointer "
                      onClick={() => {
                        handleOpenNews(item);
                      }}
                    >
                      Read more
                      <MdKeyboardDoubleArrowRight className="" size={20} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {isDialogOpen && (
              <ViewMore
                item={item}
                onCancel={() => {
                  setIsDialogOpen(false);
                }}
              />
            )}
          </div>
        </>
      )}

      {/* Pagination */}
    </div>
  );
};

export default News;
const ViewMore = ({ item, onCancel }) => {
  console.log("item", item);
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full  flex items-center justify-center z-50`}
    >
      <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
      <div className="bg-lightgray  p-4  md:w-[60%] w-5/6 rounded-md shadow-md relative z-10 mt-8">
        <div className="font-bold  md:text-xl text-sm md:text-justify text-center  pl-3 flex justify-between ">
          <h3 className="text-primary font-inter p-2 ">
            {item.title}{" "}
            <span className="text-black ">
              {item.location} {dayjs(item.time).format("DD/MM/YYYY")}
            </span>
          </h3>
          <button
            className="font-inter font-semibold cursor-pointer mr-6 mt-2 space-x-2 flex text-sm"
            onClick={() => {
              onCancel();
            }}
          >
            <BsArrowLeft size={23} className="  " />
            <h2 className="text-base"> Back</h2>
          </button>
        </div>
        <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
          <div className="flex">
            <div className="w-[50%] h-[420px] p-6 object-cover">
              <img
                src={item.image}
                alt="news image"
                className="w-full h-full p-3 object-contain"
              />
            </div>
            <div className="w-[50%] h-[420px] overflow-auto scrollbar-custom   ">
              <p className="md:pl-4 mb-4 font-sans text-para font-medium text-justify text-sm p-4  h-auto ">
                {item.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
