import api from "../api";

const baseURL = "/api/upload";

export const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return api.post(baseURL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const multipleFiles = (files) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]); // Use the correct field name "files"
  }

  return api.post(`${baseURL}/multipleFiles`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFile = (key) => {
  return api.delete(`${baseURL}/delete`, { params: { key } });
};
