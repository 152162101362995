import React, { useState, useEffect, useContext } from "react";
import { FaEdit } from "react-icons/fa";
import { MdModeEdit, MdRemoveRedEye } from "react-icons/md";

import { MdDelete } from "react-icons/md";
import check from "../../assets/Checkmark.png";
import { deleteNews, editNews, getNewses } from "../../services/news";
import dayjs from "dayjs";
import UserContext from "../context provider/UserContext";

const UpdateNews = () => {
  const [isDialogVisibleDeleteSucess, setIsDialogVisibleDeleteSucess] =
    useState(false);
  const [newsData, setNewsData] = useState([]);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      getNewses().then((res) => {
        setNewsData(res.data);
      });
    }
  }, [deleteId, editId]);

  const handleOpenDeletedSucess = () => {
    setIsDialogVisibleDeleteSucess(true);
  };

  const handleEdit = (value) => {
    setEditId(value.id);
    editNews(value.id, value.title, value.location, value.description).then(
      (res) => {
        setEditId("");
      }
    );
  };

  const handleDelete = (id) => {
    console.log("alkdsjf", id);
    setDeleteId(id);
    deleteNews(id).then((res) => {
      handleOpenDeletedSucess();
      setDeleteId("");
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsDialogVisibleDeleteSucess(false);
      clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [isDialogVisibleDeleteSucess]);

  return (
    <>
      {isDialogVisibleDeleteSucess && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-48 w-2/6 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
            <p className="text-center text-1xl font-bold text-black mt-9">
              News Deleted successfully
            </p>
            <div className="flex justify-center items-center h-14 mt-5">
              <img
                src={check}
                alt="checkout"
                className="h-full w-auto object-cover"
              />
            </div>
          </div>
        </div>
      )}
      {newsData.map((newsItem, i) => (
        <List
          data={newsItem}
          handleOpenDeletedSucess={handleDelete}
          key={i}
          handleEdit={handleEdit}
        />
      ))}
    </>
  );
};

export default UpdateNews;

const List = ({ data, handleOpenDeletedSucess, handleEdit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [publishedBy, setPublishedBy] = useState("");
  // const [editData, setEditData] = useState({});

  useEffect(() => {
    setDate(data.createdAt);
    setTitle(data.title);
    setDescription(data.description);
    setUrl(data.image);
    setLocation(data.location);
  }, [data]);

  const handlePublish = (e) => {
    e.preventDefault();
    // setEditData({ id: data.id, title, location, date, description });
    const editData = { id: data.id, title, location, description };
    handleEdit(editData);
    setIsEdit(false);
  };

  return isEdit === false ? (
    <div className="w-full md:w-6/6  bg-lightgray border border-lightgray h-auto mb-4 mt-4 items-center justify-center">
      <div key={data.id} className="flex flex-col md:flex-row p-4">
        <div className="w-full md:w-[60%] p-2 ">
          <div className="bg-gray-light mt-4 md:ml-8 ">
            <img
              src={url}
              alt="Uploaded"
              className="w-full h-full p-2 object-cover"
            />
          </div>
        </div>
        <div className="w-full md:[50%] mt-3 p-2 overflow-auto scrollbar-custom ">
          <h1 className="text-xl  font-sans font-semibold p-2">
            {title} {location} {dayjs(date).format("DD/MM/YYYY")}
          </h1>
          <p className="p-2  font-sans text-para font-medium  text-justify  ">
            {description}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-center space-x-2 text-white mx-auto p-2">
        <div key={data.id} className="flex space-x-2">
          <button
            className="bg-primary  hover:bg-black p-2 flex text-sm"
            onClick={() => {
              setIsEdit(true);
            }}
          >
            Edit news <MdModeEdit className="pl-1   " size={24} />
          </button>
          <button
            className="bg-primary  hover:bg-black p-2 flex text-sm"
            onClick={() => {
              handleOpenDeletedSucess(data.id);
            }}
          >
            Delete news <MdDelete className="pl-2    " size={24} />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`w-full mt-5 bg-lightgray border border-gray  md:flex-row p-4 ${
        isEdit === false ? "md:h-72" : "md:h-auto"
      } `}
    >
      {" "}
      <div className="flex ">
        <div className="w-full md:w-[50%] bg-gray-light p-2 md:ml-8 md:h-[90%] mt-4">
          <img
            src={url}
            alt="Uploaded"
            className="w-full h-full p-2 object-cover"
          />
        </div>
        <div className="w-full md:w-[70%] mt-6 p-2">
          <input
            type="text"
            placeholder="Title*"
            className="bg-lightgray outline-none w-full cursor-text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            type="text"
            placeholder="Location*"
            className="bg-lightgray outline-none w-full cursor-text mt-2"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          {/* <input
            type="datetime-local"
            placeholder="Date*"
            className="bg-lightgray outline-none w-full cursor-text mt-2 text-gray"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          /> */}

          <p>
            <textarea
              placeholder="Description*"
              className="bg-lightgray outline-none w-full h-28 cursor-text mt-2 scrollbar-custom"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </p>
          <input
            type="text"
            placeholder="Published by"
            className="bg-lightgray outline-none w-full mb-0 cursor-text"
            value={publishedBy}
            onChange={(e) => setPublishedBy(e.target.value)}
          />
        </div>
      </div>
      <div className="flex items-center justify-center space-x-2 text-white mx-auto p-2">
        <div key={data.id} className="flex space-x-2">
          <button
            className="bg-gray rounded-sm text-white flex p-2"
            onClick={() => {
              setIsEdit(false);
            }}
          >
            Cancel
          </button>
          <button
            className="bg-primary  hover:bg-black p-2 flex text-sm"
            onClick={(e) => {
              handlePublish(e);
            }}
          >
            Update news <MdModeEdit className="pl-1   " size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};
