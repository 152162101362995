import React from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./about";
import News from "./News";
import Footer from "./Footer";
import Entreovert from "./Entreovert";
import { Helmet } from "react-helmet-async";

const Main = () => {
  return (
    <>
      <Helmet>
        <title>Home Page - One Mind Market</title>
        <meta
          name="description"
          content="Discover detailed business profiles and local shops on One Mind Market. Find restaurants, cafes, service providers, and more. Your guide to the best local businesses."
        />

        {/* Open Graph meta tags */}
        <meta property="og:title" content="Home Page - One Mind Market" />
        <meta
          property="og:description"
          content="Discover detailed business profiles and local shops on One Mind Market. Find restaurants, cafes, service providers, and more. Your guide to the best local businesses."
        />
        <meta
          property="og:image"
          content="https://oneminassects.s3.ap-south-1.amazonaws.com/One_Mind_Market_Black_Logo.png"
        />
        <meta property="og:url" content="https://www.onemindmarket.in/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://www.onemindmarket.in/`} />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: "https://www.onemindmarket.in/",
            name: "One Mind Market",
            description:
              "Discover detailed business profiles and local shops on One Mind Market. Find restaurants, cafes, service providers, and more. Your guide to the best local businesses.",
            publisher: {
              "@type": "Organization",
              name: "One Mind Market",
            },
          })}
        </script>
      </Helmet>
      <div className="w-full">
        {/* <Navbar /> */}
        <Home />
        <About />
        <News />
        <Entreovert />
      </div>
    </>
  );
};

export default Main;
