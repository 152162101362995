import React from "react";
import logo from "../../assets/One_Mind_Market_Black_Logo_with_text.png";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { FaBriefcase, FaUserShield, FaUserCircle } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import logmob from "../../assets/Variant_04.png";

const LoginFlow1 = () => {
  return (
    <div className="h-full max-w-screen-2xl min-h-screen mx-auto w-full flex flex-col md:flex-row">
      <span className="w-full md:w-1/2 bg-lightgray hidden md:inline  items-center justify-center p-6 xl:py-48">
        <img src={logo} alt="" className="w-1/2 md:w-1/2  xl:w-1/2 mx-auto" />
        <h1 className="flex justify-center mt-16">
          One City! One Brand! One Entrepreneur!
        </h1>
      </span>
      <span>
        <img src={logmob} alt="logo" className="md:hidden" />
      </span>

      <div className="w-full md:w-1/2 p-6">
        <Link to="/">
          <div className="flex hover:text-gray cursor-pointer">
            <MdOutlineArrowBackIos className="mt-1" />
            <span className="font-bold text-1xl">Back</span>
          </div>
        </Link>

        <div className="xl:py-24 flex flex-col xl:flex-col md:flex justify-center items-start xl:items-center   md:ml-8 text-center lg:text-left">
          <div className="xl:w-[70%]">
            <h1 className="text-2xl font-semibold mt-8 pl-2">
              Welcome back to <span className="text-primary">One Mind </span>
            </h1>
            <p className="text-sm text-gray pt-3 lg:pt-0 pl-2">
              Let's add some caption
            </p>

            <Link to="/Login">
              <div className="w-full mt-3 cursor-pointer">
                <ul className="space-y-4">
                  <li className="flex items-center border  hover:border-primary p-4 group relative">
                    <FaUserCircle className="mr-2" size={24} />
                    <div className="mb-1 flex flex-col pl-6">
                      <span>User</span>
                      <span className="text-gray text-sm">
                        Add some caption
                      </span>
                    </div>
                    <IoArrowForward className="text-primary ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </li>
                  <li className="flex items-center border hover:border-primary group relative p-4">
                    <FaBriefcase className="mr-2" size={24} />
                    <div className="mb-1 flex flex-col pl-6">
                      <span>Business</span>
                      <span className="text-gray text-sm">
                        Add some caption
                      </span>
                    </div>
                    <IoArrowForward className="text-primary ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </li>
                  <li className="flex items-center border hover:border-primary group relative  p-4">
                    <FaUserShield className="mr-2" size={24} />
                    <div className="mb-1 flex flex-col pl-6">
                      <span>Admin</span>
                      <span className="text-gray text-sm">
                        Add some caption
                      </span>
                    </div>
                    <IoArrowForward className="text-primary ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </li>
                </ul>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginFlow1;
