import api from "../api";

const baseURL = "/api/business";

export const dashboard = (from, to) => {
  return api.get(`${baseURL}/dashboard`, { params: { from, to } });
};

export const enquiries = (isNew, from, to) => {
  return api.get(`${baseURL}/enquiries`, { params: { isNew, from, to } });
};

export const enquiriesExcel = (isNew, from, to) => {
  return api.get(`${baseURL}/enquiries/web`, { params: { isNew, from, to } });
};

export const jobsApplied = (isNew, from, to) => {
  return api.get(`${baseURL}/jobsApplied`, { params: { isNew, from, to } });
};

export const jobsAppliedExcel = (isNew, from, to) => {
  return api.get(`${baseURL}/jobsApplied/web`, { params: { isNew, from, to } });
};

export const getBusinessDetails = () => {
  return api.get(`${baseURL}/businessDetail`);
};

export const updateBusinessProfile = (
  id,
  address,
  mobile,
  email,
  overView,
  career,
  socialMedia,
  images,
  map
) => {
  return api.put(`${baseURL}/${id}`, {
    address,
    mobile,
    email,
    overView,
    career,
    socialMedia,
    images,
    map,
  });
};

export const deleteFile = (key) => {
  return api.post(`${baseURL}/delete`, { key });
};

export const updateSocialMedia = (id, socialMedia) => {
  return api.post(`${baseURL}/updateSocialMedia`, { id, socialMedia });
};
