import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { IoMdClose } from "react-icons/io";
import { FaArrowDown, FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { FaFileCircleQuestion } from "react-icons/fa6";
import check from "../../assets/Checkmark.png";
import {
  dashboard,
  enquiries,
  enquiriesExcel,
  jobsAppliedExcel,
} from "../../services/business";
import { getNewses } from "../../services/news";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import News from "../News";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import ExcelJS from "exceljs";
import { BsArrowLeft } from "react-icons/bs";
import UserContext from "../context provider/UserContext";
import { useNavigate } from "react-router-dom";

const Minidasbord = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDialogVisibleResume, setIsDialogVisibleResume] = useState(false);
  const [isDialogVisibleEnquries, setIsDialogVisibleEnquries] = useState(false);
  const [isDialogVisibleDownload, setIsDialogVisibleDownload] = useState(false);
  const [data, setData] = useState("");
  const [newsItems, setNewsItems] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogReadOpen, setIsDialogReadOpen] = useState(false);
  const [item, setItem] = useState("");
  const { user } = useContext(UserContext);

  const handleOpenReadNews = (item) => {
    setIsDialogReadOpen(true);
    setItem(item);
  };

  // const [isSubmitted, setIsSubmitted] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenNews = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    if (user) {
      if (startDate !== null) {
        console.log(
          "endDate === null || endDate === undefined",
          endDate === null || endDate === undefined
        );
        if (endDate === null || endDate === undefined) {
          const end = dayjs().format("YYYY-MM-DD");

          console.log("test");
          dashboard(startDate, end).then((res) => {
            setData(res.data);
          });
          return;
        } else {
          console.log("falie");
          dashboard(startDate, endDate).then((res) => {
            setData(res.data);
          });
          return;
        }
      } else {
        dashboard().then((res) => {
          // console.log("lakfdja", res.data);
          setData(res.data);
        });
        return;
      }
    } else {
      navigate("/Login");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getNewses().then((res) => {
      setNewsItems(res.data);
    });
  }, []);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleDatePicker = () => {
    setIsOpen(false);
    // setIsDialogVisibleResume(false);
  };

  const handleCloseDialog1 = () => {
    setIsDialogVisibleResume(false);
  };

  const handleBtnClick = (value) => {
    setIsDialogVisibleResume(true);
    setIsDialogVisibleEnquries(value);
  };

  const handleCloseDialogDownload = () => {
    setIsDialogVisibleDownload(true);
  };

  const generateExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    worksheet.columns = [
      { header: "Name", key: "name", width: 20 },
      { header: "Mobile", key: "mobile", width: 15 },
      { header: "Email", key: "email", width: 30 },
      { header: "Enquiry", key: "enquiry", width: 40 },
    ];

    data.forEach((item) => {
      worksheet.addRow(item);
    });

    // Serialize the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  };

  const generateJobsAppliedExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    worksheet.columns = [
      { header: "Name", key: "name", width: 20 },
      { header: "Mobile", key: "mobile", width: 15 },
      { header: "Email", key: "email", width: 30 },
      { header: "Resume Link", key: "image", width: 80 },
    ];

    data.forEach((item, index) => {
      const rowNumber = index + 2;
      if (item.image) {
        worksheet.addRow(item);
        worksheet.getCell(`D${rowNumber}`).value = {
          text: item.image,
          hyperlink: item.image,
        };
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  };

  const handleNewDownload = async () => {
    if (isDialogVisibleEnquries === true) {
      //enquiry download
      await enquiriesExcel(true, startDate, endDate)
        .then(async (res) => {
          const { data } = res;
          const excel = await generateExcel(data);
          const blob = new Blob([excel], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "New Enquiry data.xlsx";

          // Append the anchor element to the document body and click it to trigger the download
          document.body.appendChild(a);
          a.click();

          // Revoke the URL to release memory
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading Excel file:", error);
          // Handle error as needed
        });
    } else {
      console.log("hit");
      jobsAppliedExcel(true, startDate, endDate)
        .then(async (res) => {
          const { data } = res;
          const excel = await generateJobsAppliedExcel(data);
          const blob = new Blob([excel], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "New Jobs applied resumes.xlsx";

          // Append the anchor element to the document body and click it to trigger the download
          document.body.appendChild(a);
          a.click();

          // Revoke the URL to release memory
          window.URL.revokeObjectURL(url);
          handleCloseDialogDownload();
        })
        .catch((error) => {
          console.error("Error downloading ZIP file:", error);
          // Handle error as needed
        });
    }
  };

  const handleAllDownload = () => {
    if (isDialogVisibleEnquries === true) {
      //enquiry download
      enquiriesExcel(false, startDate, endDate)
        .then(async (res) => {
          const { data } = res;
          const excel = await generateExcel(data);
          const blob = new Blob([excel], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "Enquiry data.xlsx";

          // Append the anchor element to the document body and click it to trigger the download
          document.body.appendChild(a);
          a.click();

          // Revoke the URL to release memory
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading Excel file:", error);
          // Handle error as needed
        });
    } else {
      jobsAppliedExcel(false, startDate, endDate)
        .then(async (res) => {
          const { data } = res;
          const excel = await generateJobsAppliedExcel(data);
          const blob = new Blob([excel], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "New Jobs applied resumes.xlsx";

          // Append the anchor element to the document body and click it to trigger the download
          document.body.appendChild(a);
          a.click();

          // Revoke the URL to release memory
          window.URL.revokeObjectURL(url);
          handleCloseDialogDownload();
        })
        .catch((error) => {
          console.error("Error downloading ZIP file:", error);
          // Handle error as needed
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsDialogVisibleDownload(false);
      clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [isDialogVisibleDownload]);

  return (
    <>
      <div className="container mx-auto mt-28">
        <div className="flex justify-between mt-5">
          <h1 className="text-2xl font-bold mt-3">Mini Dashboard</h1>
          <div className="text-white">
            {isOpen === false ? (
              <button
                className="bg-primary p-2  hover:bg-black space-x-2 flex items-center"
                onClick={handleButtonClick}
              >
                <span>Filter by date</span>
                <FaCalendarAlt size={20} />
              </button>
            ) : (
              <>
                <button
                  className="bg-primary   hover:bg-black p-2 space-x-2 ml-auto  flex items-center"
                  onClick={handleToggleDatePicker}
                >
                  <span>Filter by date</span>
                  <FaCalendarAlt size={20} />
                </button>
                <DatePicker
                  title="From Date:"
                  selected={startDate}
                  onChange={(date) =>
                    setStartDate(dayjs(date).format("YYYY-MM-DD"))
                  }
                  placeholderText="From date"
                  className="rounded mt-2 text-black text-center w-full"
                />
                <DatePicker
                  title="To Date:"
                  selected={endDate}
                  onChange={(date) =>
                    setEndDate(dayjs(date).format("YYYY-MM-DD"))
                  }
                  placeholderText="To date"
                  className="rounded mt-2 text-black text-center w-full"
                />
              </>
            )}
          </div>
        </div>
        <div className="border flex justify-center flex-wrap">
          <div
            className="w-full sm:w-1/2 lg:w-48 mt-4   mr-2 ml-2"
            onClick={() => {
              handleBtnClick(true);
            }}
          >
            <button className="bg-primary w-full h-44 pl-16 text-white">
              <h1 className="mb-8 pr-24 text-xl">{data.enquiries}</h1>
              <FaFileCircleQuestion size={44} className="md:ml-0 ml-28" />
            </button>
            <p className="flex justify-center">Number of enquires</p>
          </div>
          <div className="w-full sm:w-1/2 lg:w-48 mt-4 mr-2 ml-2">
            <button
              className="bg-primary w-full h-44 pl-16 text-white"
              onClick={() => {
                handleBtnClick(false);
              }}
            >
              <h1 className="mb-8 pr-24 text-xl">{data.jobsApplied}</h1>
              <FaArrowDown className="md:ml-0 ml-28" size={44} />
            </button>
            {isDialogVisibleResume && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto w-82 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  <div className="flex justify-end">
                    <IoMdClose
                      className="text-black cursor-pointer"
                      size={24}
                      onClick={handleCloseDialog1}
                    />
                  </div>

                  <h1 className="text-center text-2xl font-bold text-black mt-4 md:mt-9">
                    Download{" "}
                    {isDialogVisibleEnquries === false ? "resume" : "enquiries"}
                  </h1>

                  <div className=" md:flex-row md:space-x-4 md:mt-16">
                    <button
                      className="bg-primary  hover:bg-black py-4 px-2 w-full md:w-auto mb-2 md:mb-0 text-white"
                      onClick={handleNewDownload}
                    >
                      Download new{" "}
                      {isDialogVisibleEnquries === false
                        ? "resume"
                        : "enquiries"}
                    </button>

                    <button
                      className="bg-lightgray    text-black py-4 px-2 w-full md:w-auto"
                      onClick={handleAllDownload}
                    >
                      Download all{" "}
                      {isDialogVisibleEnquries === false
                        ? "resume"
                        : "enquiries"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isDialogVisibleDownload && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto w-82 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  <p className="text-center text-1xl font-bold text-black mt-9">
                    Resume download successfully
                  </p>
                  <div className="flex justify-center items-center h-14 mt-5">
                    <img
                      src={check}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
            )}
            <p className="flex justify-center">Download resume</p>
          </div>
        </div>
      </div>
      <div className="  mb-4  mt-28 ">
        {/* <h1 className="text-xl md:text-3xl font-semibold     ">Latest News</h1> */}
        {/* {newsItems.map((item, index) => (
          <div
            key={index}
            className="bg-lightgray w-full  border border-gray md:w-full justify-center items-center mx-auto  flex flex-col mb-4 md:flex-row h-90 md:h-[50vh]"
          >
            <div className="w-full md:w-[50%] mt-4 md:mt-0 md:mr-4">
              <img
                src={item.image}
                alt=""
                className="w-full max-h-full p-6 object-cover"
              />
            </div>
            <div className="w-full md:w-[70%] px-4 py-5 overflow-hidden">
              <h1 className="text-xl font-semibold md:pl-4 font-sans mb-3 ">
                {item.title} {item.location}{" "}
                {dayjs(item.time).format("DD/MM/YYYY")}
              </h1>
              <p className="md:pl-4 mb-4 font-sans text-para h-36 overflow-hidden  font-inter font-medium text-justify  ">
                {item.description}
              </p>
              <div className="flex justify-end ">
                <button
                  className="font-semibold font-sans    flex text-black p-1 text-sm rounded-full  cursor-pointer "
                  onClick={() => {
                    handleOpenReadNews(item);
                  }}
                >
                  Read more
                  <MdKeyboardDoubleArrowRight className="" size={20} />
                </button>
              </div>
            </div>
          </div>
        ))}
        {isDialogReadOpen && (
          <ViewMore
            item={item}
            onCancel={() => {
              setIsDialogReadOpen(false);
            }}
          />
        )} */}
        <News className="w-full" />
      </div>
    </>
  );
};

export default Minidasbord;
const ViewMore = ({ item, onCancel }) => {
  console.log("item", item);
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full  flex items-center justify-center z-50`}
    >
      <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
      <div className="bg-lightgray  p-4 w-full md:w-[60%] rounded-md shadow-md relative z-10 mt-8">
        <div className="font-bold text-xl pl-3 flex justify-between ">
          <h1 className="text-primary font-inter p-2">
            {item.title}{" "}
            <span className="text-black ">
              {item.location} {dayjs(item.time).format("DD/MM/YYYY")}
            </span>
          </h1>
          <button
            className="font-inter font-semibold cursor-pointer mr-6 mt-2 space-x-2 flex text-sm"
            onClick={() => {
              onCancel();
            }}
          >
            <BsArrowLeft size={23} className="  " />
            <h1 className="text-base"> Back</h1>
          </button>
        </div>
        <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
          <div className="flex">
            <div className="w-[50%] h-[420px] p-6 object-cover">
              <img
                src={item.image}
                alt=""
                className="w-full h-full p-3 object-contain"
              />
            </div>
            <div className="w-[50%] h-[420px] overflow-auto scrollbar-custom   ">
              <p className="md:pl-4 mb-4 font-sans text-para font-medium text-justify text-sm p-4  h-auto ">
                {item.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
