import React, { useState, useEffect, useContext } from "react";
import { LuUpload } from "react-icons/lu";
import news from "../../assets/news.jpg";
import pdf from "../../assets/Export Pdf.png";
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { SlArrowLeft, SlArrowRight, SlCallEnd } from "react-icons/sl";
import { BsArrowLeft, BsPinMapFill } from "react-icons/bs";
import { TbBriefcaseFilled } from "react-icons/tb";
import { GrOverview } from "react-icons/gr";
import { IoMdClose, IoMdMail } from "react-icons/io";
import check from "../../assets/Checkmark.png";
import cancel from "../../assets/Cancel.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDetails } from "../../services/home";
import { getNewses } from "../../services/news";
import dayjs from "dayjs";
import { enquiry, uploadResume } from "../../services/home";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import News from "../News";
import UserContext from "../context provider/UserContext";
import ImageGallery from "react-image-gallery";
import { Helmet } from "react-helmet-async";

const UserPageview = () => {
  const params = useParams();
  const {
    classification,
    location: businessLocation,
    name: businessname,
  } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEnquireSucces, setEnquireSucess] = useState(false);

  const [isDialogReadOpen, setIsDialogReadOpen] = useState(false);

  const [isDialogOpenUpload, setIsDialogOpenUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogVisibleSucess, setIsDialogVisibleSucess] = useState(false);
  const [isExportSuccess, setIsExportSuccess] = useState(false);
  const [isExportDecline, setIsExportDecline] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [images, setImages] = useState([]);
  const [firstImage, setFirstImage] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [enquire, setEnquire] = useState("");
  const [newsItems, setNewsItems] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [enquireError, setEnquireError] = useState(false);
  const [item, setItem] = useState("");
  const { user } = useContext(UserContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [allImages, setAllImages] = useState([]);
  const location = useLocation();
  const id = location.state?.id || "";

  // Function to handle opening the image
  const handleOpenImage = (index) => {
    setSelectedImageIndex(index);
  };

  const nextImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  // Function to handle previous image
  const prevImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };
  const handleOpenNews = (item) => {
    setIsDialogReadOpen(true);
    setItem(item);
  };

  useEffect(() => {
    getNewses().then((res) => {
      setNewsItems(res.data);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      getDetails(params.id).then((res) => {
        setData(res.data);
        console.log("res", res.data);
        const { socialMedia } = res.data;
        const firstObject = res.data.images[0];
        const restObjects = res.data.images.slice(1);
        if (res.data.images.length !== 0) {
          const images = res.data?.images.map((i) => {
            return {
              original: i.image,
              thumbnail: i.image,
            };
          });
          setAllImages(images);
        }
        setFirstImage(firstObject);
        setImages(restObjects);
        setFacebookLink(socialMedia?.facebook);
        setInstagramLink(socialMedia?.instagram);
        setGoogleLink(socialMedia?.google);
        setLinkedIn(socialMedia?.linkedin);
      });
    }
  }, [params.id]);

  const handleOpenEnquire = () => {
    if (user !== null && user !== undefined) {
      setIsDialogOpen(true);
    } else {
      navigate("/Login");
    }
  };

  // console.log("data", facebookLink, googleLink, instagramLink, linkedin);

  const handleOpenUpload = () => {
    if (user !== null && user !== undefined) {
      setIsDialogOpenUpload(true);
    } else {
      navigate("/Login");
    }
  };
  const handleOpenResume = () => {
    setIsDialogVisibleSucess(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  // console.log("all", allImages);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      // Handle uploading the selected file, e.g., send it to a server
      // console.log("Selected file:", selectedFile);
      // Close the upload dialog
      setIsDialogOpenUpload(false);
      uploadResume(selectedFile, data.id)
        .then((res) => {
          // Show export success dialog
          setIsExportSuccess(true);
        })
        .catch((error) => {
          console.log("error", error);
          setIsExportDecline(true);
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsExportSuccess(false);
      setIsExportDecline(false);
      clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [isExportSuccess, isExportDecline]);

  const handleEnquiry = (e) => {
    if (!name) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!email) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (!mobile) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    if (!enquire) {
      setEnquireError(true);
    } else {
      setEnquireError(false);
    }
    if (name && email && mobile && enquire) {
      // If all fields are valid, proceed with the form submission
      enquiry(name, email, mobile, enquire, data.id)
        .then((res) => {
          setEmail("");
          setName("");
          setMobile("");
          setEnquire("");
          handleEnquiryDialog();
          handleCloseDialog();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handleEnquiryDialog = () => {
    setEnquireSucess(true);
    setTimeout(() => {
      setEnquireSucess(false);
    }, 3000);
  };

  return (
    <>
      <Helmet>
        <title>{businessname} - One Mind Market</title>
        <meta
          name="description"
          content={`Details about ${businessname} in ${classification}.`}
        />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content={`${businessname} - One Mind Market`}
        />
        <meta
          property="og:description"
          content={`Details about ${businessname} in ${classification}.`}
        />
        <meta property="og:image" content={firstImage?.image} />
        <meta
          property="og:url"
          content={`https://www.onemindmarket.in/${location.pathname}`}
        />
        <meta property="og:type" content="category" />
        <link
          rel="canonical"
          href={`https://www.onemindmarket.in/${location.pathname}`}
        />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Category",
            name: businessname,
            image: firstImage?.image,
            description: classification,
            brand: {
              "@type": "Brand",
              name: businessname,
            },
          })}
        </script>
      </Helmet>
      <div className="max-w-screen-2xl w-4/5 md:px-12 mx-auto mt-44 ">
        <div className="flex flex-col md:flex-row md:items-center">
          <h1 className="text-2xl font-sans font-bold text-center  md:text-center  p-2">
            {data?.name}
          </h1>

          <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0 p-4 md:ml-3 text-white">
            <span className="bg-primary  text-center  md:text-center rounded-full font-inter text-sm p-0.5  px-4">
              {data.location?.name}
            </span>

            <span className="bg-primary text-center  md:text-center rounded-full font-inter text-sm p-0.5  px-4">
              {data.category?.name}
            </span>
          </div>
        </div>

        <div className=" ">
          <div className="flex flex-col md:flex-row space-x-2">
            {firstImage !== null &&
            firstImage !== undefined &&
            firstImage.length !== 0 ? (
              <div className="w-full md:w-1/2 h-[auto] bg-lightgray">
                <div
                  className="relative group"
                  onClick={() => handleOpenImage(0)}
                >
                  <img
                    src={firstImage.image}
                    alt="Uploaded"
                    className="w-full h-[290px]  p-2   object-fit transition-transform transform"
                  />
                </div>
              </div>
            ) : (
              <div className="sm:w-1/2 h-[auto] bg-lightgray mt-1"></div>
            )}
            <div className="w-full md:w-1/2 h-72 grid grid-cols-2 grid-rows-2 gap-2 mb-1">
              {/* Render the images */}
              {images !== null &&
                images.map((pic, index) => (
                  <div
                    key={index}
                    onClick={() => handleOpenImage(index + 1)}
                    className="relative group row-span-1 col-span-1 bg-lightgray p-2"
                  >
                    <img
                      src={pic.image}
                      alt="Uploaded"
                      className="w-full h-full object-fit  transition-transform transform "
                    />
                  </div>
                ))}
              {/* Render default images if the number of images is less than four */}
              {Array.from({ length: Math.max(4 - images.length, 0) }).map(
                (_, index) => (
                  <div
                    key={`default-${index}`}
                    className="row-span-1 col-span-1 bg-lightgray p-2"
                  ></div>
                )
              )}
            </div>
          </div>
        </div>
        {selectedImageIndex !== null && (
          // <div className="w-full h-full fixed bg-black bg-opacity-80 top-0 mx-auto items-center justify-center left-0 flex  mt-10  z-50 ">
          <div className="relative">
            <ImageGallery
              items={allImages}
              autoPlay={true}
              showFullscreenButton={false}
              showPlayButton={false}
            />
            <button
              className="absolute top-2 right-2 text-white bg-gray rounded-full   "
              onClick={() => setSelectedImageIndex(null)}
            >
              <IoMdClose size={34} />
            </button>
          </div>
        )}
        <div className="border h-auto text-black p-2 mt-3 sm:w-full md:w-6/6 ">
          <label
            htmlFor="message"
            className=" text-sm font-semibold font-sans  flex p-2 text-1xl"
          >
            Over view <GrOverview className="pl-2" size={24} />
          </label>
          <p className="px-4  text-justify text-para h-80  overflow-auto font-medium font-sans scrollbar-custom">
            {data.overView}
          </p>
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-3">
          <div className="border h-96 md:h-90 w-full md:w-1/2 text-black p-2 ">
            <div>
              <label
                htmlFor="message"
                className="mb-2 text-sm font-semibold font-sans justify-between flex p-2 text-1xl"
              >
                Address{" "}
                {data.map && (
                  <span className="flex">
                    <FaMapLocationDot className="pl-2" size={23} />{" "}
                    <a className="text-primary pl-2" href={data.map}>
                      Map
                    </a>
                  </span>
                )}
              </label>
            </div>
            <div>
              <p className="text-sm text-para p-2 font-medium font-sans">
                {data.address}
              </p>

              <label
                htmlFor="message"
                className="mb-2 text-sm font-semibold font-sans  mt-3 flex p-2 text-1xl"
              >
                Contact <SlCallEnd className="pl-2" size={23} />
              </label>
              <p className="text-para font-medium p-2 flex font-sans">
                Call us at: <span className="font-bold"> {data.mobile} </span>
              </p>
              <p className="text-para font-medium p-2 flex font-sans">
                Email us at: <span className="font-bold">{data.email}</span>
              </p>
              <div className="flex mt-2  items-center space-x-4">
                {facebookLink !== undefined && facebookLink.length > 0 ? (
                  <a href={facebookLink}>
                    <FaFacebook
                      className="icon block cursor-pointer hover:text-gray"
                      size={24}
                    />
                  </a>
                ) : (
                  ""
                )}
                {googleLink !== undefined && googleLink.length > 0 ? (
                  <a href={googleLink}>
                    <FaGoogle
                      className="icon block cursor-pointer hover:text-gray"
                      size={24}
                    />
                  </a>
                ) : (
                  ""
                )}
                {linkedin !== undefined && linkedin.length > 0 ? (
                  <a href={linkedin}>
                    <FaLinkedin
                      className="icon block cursor-pointer hover:text-gray"
                      size={24}
                    />
                  </a>
                ) : (
                  ""
                )}
                {instagramLink !== undefined && instagramLink.length > 0 ? (
                  <a href={instagramLink}>
                    <FaInstagram
                      className="icon block cursor-pointer hover:text-gray"
                      size={24}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="">
              <div
                className={`  ${
                  data.address ||
                  data.mobile ||
                  data.email ||
                  facebookLink ||
                  googleLink ||
                  instagramLink ||
                  linkedin
                    ? "md:-mt-24 bottom-5"
                    : " md:-mt-28 "
                }`}
              >
                <button
                  className="bg-primary hover:bg-black p-2 flex -mt-2 md:mt-36 md:mb-1 mb-4 py-2 font-inter text-white"
                  onClick={handleOpenEnquire}
                >
                  Enquire Now <IoMail className="pl-2" size={24} />
                </button>
              </div>
            </div>

            <div
              className={`fixed top-0 left-0 w-full h-full mt-10 flex items-center justify-center z-50 ${
                isDialogOpen ? "block" : "hidden"
              }`}
            >
              <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
              <div className="bg-lightgray p-4 w-full md:w-[70%] rounded-md shadow-md relative z-10">
                <div className="font-bold text-xl pl-3">
                  <h2 className="text-primary">
                    Enquire <span className="text-black">Now</span>
                  </h2>
                </div>

                <div className="bg-white border border-primary w-full md:w-[95%] mt-4 p-4 rounded-md  ml-4    overflow-auto scrollbar-custom max-h-[50vh]">
                  <div className="flex flex-col md:flex-row items-center mb-4">
                    <h3 className="mr-4 md:w-[20%] ">Full name*</h3>
                    <input
                      type="text"
                      className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                      placeholder="Enter your full name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {nameError && (
                    <span className="text-red flex justify-center">
                      Please enter your full name.
                    </span>
                  )}

                  <div className="flex flex-col md:flex-row items-center mb-4">
                    <h3 className="mr-4 md:w-[20%] ">Email address*</h3>
                    <input
                      type="text"
                      className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {emailError && (
                    <span className="text-red flex justify-center">
                      Please enter a valid email address.
                    </span>
                  )}

                  <div className="flex flex-col md:flex-row items-center mb-4">
                    <h3 className="mr-4 md:w-[20%]">Phone number*</h3>
                    <input
                      type="text"
                      className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                      placeholder="Enter your phone number"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  {mobileError && (
                    <span className="text-red flex justify-center">
                      Please enter a valid phone number.
                    </span>
                  )}

                  <div className="flex flex-col md:flex-row items-center mb-4">
                    <h3 className="mr-4 md:w-[20%] ">Enquire*</h3>
                    <textarea
                      className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] h-24 bg-lightgray outline-none resize-none"
                      placeholder="Write your business idea with a maximum of 250 words"
                      value={enquire}
                      onChange={(e) => setEnquire(e.target.value)}
                    ></textarea>
                  </div>
                  {enquireError && (
                    <span className="text-red flex justify-center">
                      Please provide an enquiry.
                    </span>
                  )}
                </div>
                <div className="flex justify-center space-x-3 items-center mt-4 pl-0 md:pl-6">
                  <button
                    className="bg-gray rounded-sm text-white flex p-2"
                    onClick={handleCloseDialog}
                  >
                    Close
                  </button>
                  <button
                    className="bg-primary  hover:bg-black font-inter  rounded-sm text-white flex p-2"
                    onClick={handleEnquiry}
                  >
                    Enquire Now
                    <IoMdMail className="pl-1" size={23} />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="border h-36 md:h-auto w-full md:w-1/2 text-black p-2 relative">
            <label
              htmlFor="message"
              className="mb-2 text-sm font-semibold font-sans flex p-2 text-1xl"
            >
              Career <TbBriefcaseFilled className="pl-2" size={23} />
            </label>
            <p className="  text-justify text-para font-medium font-sans">
              {data.career}
            </p>

            <div
              className={`md:mt-56  absolute bottom-4 ${
                data.address ||
                data.mobile ||
                data.email ||
                facebookLink ||
                googleLink ||
                instagramLink ||
                linkedin
                  ? " md:bottom-3 bottom-0"
                  : "md:bottom-3 bottom-1 "
              }`}
            >
              <button
                className="bg-primary  hover:bg-black font-inter p-2 flex mt-4  text-white"
                onClick={handleOpenUpload}
              >
                Upload Resume <LuUpload className="pl-2" size={23} />
              </button>
            </div>
            {isDialogOpenUpload && (
              <div className="fixed top-0 left-0 w-full h-full  bg-white bg-opacity-50    z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white  border border-primary shadow-red p-4 h-auto w-84 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  {/* <div className="border border-primary rounded-sm p-4"> */}
                  <div className="flex justify-end items-end">
                    <IoMdClose
                      className="text-black  cursor-pointer"
                      size={20}
                      onClick={() => {
                        setIsDialogOpenUpload(false);
                      }}
                    />
                  </div>
                  <div className="text-center text-1xl font-semibold text-black mt-4">
                    <h2>
                      Import your resume with{" "}
                      <span>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          className="hidden"
                          id="resume-upload"
                        />
                        <label
                          className="text-primary  hover:cursor-pointer"
                          htmlFor="resume-upload"
                        >
                          .pdf extension{" "}
                        </label>
                      </span>
                      only
                    </h2>
                  </div>
                  <div className="flex justify-center items-center h-14 mt-5">
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      className="hidden"
                      id="resume-upload"
                    />
                    <label htmlFor="resume-upload" className="cursor-pointer">
                      <img
                        src={pdf}
                        alt="pdf"
                        className="h-12 w-auto object-cover cursor-pointer"
                        title="Click here to select a PDF file"
                      />
                    </label>
                  </div>
                  {selectedFile && (
                    <>
                      <div className="text-center ">
                        Selected file: {selectedFile.name}
                      </div>
                      <div className="flex justify-center items-center mt-2">
                        <button
                          onClick={handleUpload}
                          className="bg-primary text-white px-2 py-1  text-sm rounded-md hover:bg-primary-dark"
                        >
                          Upload
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              // </div>
            )}
            {isEnquireSucces && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50  flex justify-center items-center">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-primary p-4 h-48 w-2/6 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  {/* <div className="border border-primary rounded-sm p-4"> */}
                  <p className="text-center text-1xl font-bold text-black mt-9">
                    Enquiry&nbsp;
                    <span className="text-primary">submitted successfully</span>
                  </p>
                  <div className="flex justify-center items-center h-14 mt-5">
                    <img
                      src={check}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
              // </div>
            )}
            {isExportSuccess && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50  flex justify-center items-center">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-primary p-4 h-48 w-2/6 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  {/* <div className="border border-primary rounded-sm p-4"> */}
                  <p className="text-center text-1xl font-bold text-black mt-9">
                    Your resume{" "}
                    <span className="text-primary">exported successfully</span>
                  </p>
                  <div className="flex justify-center items-center h-14 mt-5">
                    <img
                      src={check}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
              // </div>
            )}
            {isExportDecline && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-primary p-4 h-48 w-2/6 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  {/* <div className="border border-primary rounded-sm p-4"> */}
                  <p className="text-center text-1xl font-bold text-black mt-9">
                    Your resume{" "}
                    <span className="text-primary">
                      {" "}
                      is not in .pdf extension
                    </span>
                  </p>
                  <div className="flex justify-center items-center h-14 mt-5">
                    <img
                      src={cancel}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
              // </div>
            )}
          </div>
        </div>

        <div className="mt-24 ">
          {/* <h3 className="text-2xl font-inter  font-semibold">Latest News</h3> */}
          <div className="w-full mt-7 ">
            <News />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPageview;
const ViewMore = ({ item, onCancel }) => {
  // console.log("item", item);
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full  flex items-center justify-center z-50`}
    >
      <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
      <div className="bg-lightgray  p-4 w-full md:w-[60%] rounded-md shadow-md relative z-10 mt-8">
        <div className="font-bold text-xl pl-3 flex justify-between ">
          <h3 className="text-primary font-inter p-2">
            {item.title}{" "}
            <span className="text-black ">
              {item.location} {dayjs(item.time).format("DD/MM/YYYY")}
            </span>
          </h3>
          <button
            className="font-inter font-semibold cursor-pointer mr-6 mt-2 space-x-2 flex text-sm"
            onClick={() => {
              onCancel();
            }}
          >
            <BsArrowLeft size={23} className="  " />
            <h3 className="text-base"> Back</h3>
          </button>
        </div>
        <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
          <div className="flex">
            <div className="w-[50%] h-[420px] p-6 object-cover">
              <img
                src={item.image}
                alt=""
                className="w-full h-full p-3 object-contain"
              />
            </div>
            <div className="w-[50%] h-[420px] overflow-auto scrollbar-custom   ">
              <p className="md:pl-4 mb-4 font-sans text-para font-medium text-justify text-sm p-4  h-auto ">
                {item.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
