import React, { useState, useEffect, useContext } from "react";
import logo from "../assets/Variant_02.png";
import { FaXmark, FaBars } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { FaTimes } from "react-icons/fa";
import UserContext from "./context provider/UserContext";
import { fetchAuthToken } from "../services/user";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const { user, setUser } = useContext(UserContext);
  const [btn, setBtn] = useState(false);
  const [visibleNavItems, setVisibleNavItems] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("refreshToken");
    if (token !== null && token.length > 0) {
      fetchAuthToken()
        .then((response) => {
          const { authToken, refreshToken, expiresAt, user } = response.data;
          localStorage.setItem("authToken", authToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("expiresAt", expiresAt);
          setUser(user);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (user) {
      setBtn(true);
    } else {
      setBtn(false);
    }
  }, [btn, user]);

  // useEffect to update visibleNavItems when user changes
  useEffect(() => {
    const updatedNavItems = navItem.filter(({ link }) => {
      if (user !== null) {
        if (user.type === "user") {
          return link !== "Admin" && link !== "Business";
        } else if (user.type === "business") {
          return link !== "Admin";
        } else if (user.type === "admin") {
          return link !== "Business";
        } else {
          return link !== "Admin" && link !== "Business"; // Display all items if user type is not recognized
        }
      } else {
        return link !== "Admin" && link !== "Business" && link !== "Profile";
      }
    });

    setVisibleNavItems(updatedNavItems);
  }, [user]); // Dependency array includes 'user', so this effect runs whenever 'user' changes

  const renderProfile = btn;

  // const handleLinkClick = (path) => {
  //   navigateToHome(path);
  //   setActiveLink(path);
  // };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLoginClick = () => {
    setIsLoginClicked(true);
  };

  const handleSignUpClick = () => {
    setIsLoginClicked(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresAt");
    setUser("");
    navigate("/");
  };

  // console.log("alkjsfdklfds", user);

  const navItem = [
    { link: "Home", path: "home" },
    { link: "About Us ", path: "about" },
    { link: "News", path: "news" },
    { link: "Live streaming", path: "Live" },
    { link: "Profile", path: "profile" },
    { link: "Admin", path: "admin" },
    { link: "Business", path: "business" },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const navigateToHome = (to) => {
    // console.log("lakjfdsk;", to);
    if (to === "Live") {
      return navigate("/Live");
    }

    if (to === "profile") {
      return navigate("/profile");
    }

    if (to === "business") {
      return navigate("/business");
    }

    if (to === "admin") {
      return navigate("/admin");
    }

    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const targetElement = document.getElementById(to);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  };

  return (
    <>
      <nav className="bg-white opacity-100 md:px-14  md:w-6/6 w-full p-4 max-w-screen-2xl  mx-auto text-black  fixed top-0 right-0 left-0">
        <div className="text-lg md:flex container mx-auto font-medium items-center  ">
          <div className="flex items-center justify-between md:justify-evenly  space-x-16 mx-auto">
            <a href="/">
              <img src={logo} alt="logo" className="w-56" />
            </a>
            <div className="md:flex hidden ml-8">
              <ul className="flex space-x-7  font-inter  font-medium text-nav text-lg cursor-pointer p-2">
                {visibleNavItems.map(({ link, path }) =>
                  // Render navigation item conditionally based on renderProfile variable
                  renderProfile || link !== "Profile" ? (
                    <ScrollLink
                      key={path}
                      activeClass="text-primary"
                      onClick={() => navigateToHome(path)}
                      to={path}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                    >
                      <li
                        className={`inline-block ${
                          location.pathname === `/${path}`
                            ? "text-primary"
                            : "hover:text-primary"
                        }`}
                      >
                        {link}
                      </li>
                    </ScrollLink>
                  ) : null
                )}
              </ul>
            </div>
            <div className="ml-9 md:flex hidden space-x-2 font-semibold text-nav text-base font-inter  ">
              {btn === true ? (
                <>
                  <Link
                    to="/logout"
                    className={`p-2 ${
                      !isLoginClicked
                        ? "text-white bg-primary hover:bg-black"
                        : ""
                    }`}
                    onClick={handleLogout}
                  >
                    Log out
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/Login"
                    className={`p-2 ${
                      isLoginClicked
                        ? "text-white bg-primary hover:text-primary"
                        : ""
                    }`}
                    onClick={handleLoginClick}
                  >
                    Login
                  </Link>

                  <Link
                    to="/Signup"
                    className={`p-2 ${
                      !isLoginClicked
                        ? "text-white bg-primary hover:bg-black"
                        : ""
                    }`}
                    onClick={handleSignUpClick}
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
            <div className="md:hidden ">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none   focus:text-gray"
              >
                {isMenuOpen ? (
                  <FaTimes className="w-6 h-6 text-primary cursor-pointer transition duration-300 transform rotate-180" />
                ) : (
                  <FaBars className="w-6 h-6 text-primary cursor-pointer  transition duration-300 transform rotate-180" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`space-y-4 px-4 pt-28 bg-black opacity-50 text-white ${
          isMenuOpen ? "block fixed top-4 right-0 left-0" : "hidden"
        }`}
      >
        {visibleNavItems.map(({ link, path }) =>
          // Render navigation item conditionally based on renderProfile variable
          renderProfile || link !== "Profile" ? (
            <ScrollLink
              key={path}
              activeClass="text-primary"
              onClick={() => navigateToHome(path)}
              to={path}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="block hover:text-gray cursor-pointer pl-4 md:pl-16"
            >
              <li className="inline-block">{link}</li>
            </ScrollLink>
          ) : null
        )}
        <div className="space-x-3 ml-3 font-semibold flex   items-center">
          {btn === true ? (
            <>
              <Link
                to="/logout"
                className={`p-2 ${
                  !isLoginClicked ? "text-white bg-primary hover:bg-black" : ""
                }`}
                onClick={handleLogout}
              >
                Log out
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/Login"
                className={`p-2 ${
                  isLoginClicked
                    ? "text-white bg-primary hover:text-primary"
                    : ""
                }`}
                onClick={handleLoginClick}
              >
                Login
              </Link>

              <Link
                to="/Signup"
                className={`p-2 ${
                  !isLoginClicked ? "text-white bg-primary hover:bg-black" : ""
                }`}
                onClick={handleSignUpClick}
              >
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
