import React, { useState, useEffect } from "react";
import nav from "../../assets/Variant_04.png";
import { FaEdit } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import check from "../../assets/Checkmark.png";
import { uploadFile } from "../../services/upload";
import { createNews } from "../../services/news";
const UpdateNewspage = () => {
  const [updatedNew, setUpdatedNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDialogVisibleSucess, setIsDialogVisibleSucess] = useState(false);
  const [isDialogVisibleDeleteSucess, setIsDialogVisibleDeleteSucess] =
    useState(false);

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [key, setKey] = useState("");
  const [url, setUrl] = useState("");
  const [publishedBy, setPublishedBy] = useState("");

  console.log(updatedNew, "flads;jf");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  const handleOpenSucess = () => {
    setIsDialogVisibleSucess(true);
  };
  const handleOpenDeletedSucess = () => {
    setIsDialogVisibleDeleteSucess(true);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setIsDialogVisibleDeleteSucess(false);
      clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [isDialogVisibleDeleteSucess]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsDialogVisibleSucess(false);
      clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [isDialogVisibleSucess]);

  const handlePublish = (e) => {
    e.preventDefault();
    if (
      title.length === 0 ||
      location.length === 0 ||
      description.length === 0
    ) {
      return alert("The placeholder that (*) is required");
    }

    if (selectedImage) {
      uploadFile(selectedImage).then((res) => {
        console.log("res", res);
        const {
          upload: { key, url },
        } = res.data;
        createNews(title, location, description, key, url, publishedBy)
          .then((res) => {
            handleOpenSucess();
            handleDelete();
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    } else {
      const key = "news.jpg";
      const url = "https://oneminassects.s3.ap-south-1.amazonaws.com/news.jpg";
      createNews(title, location, description, key, url, publishedBy)
        .then((res) => {
          handleOpenSucess();
          handleDelete();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handleDelete = () => {
    setTitle("");
    setDescription("");
    setPublishedBy("");
    setKey("");
    setUrl("");
    setLocation("");
    setSelectedImage("");
    setUpdatedNew(false);
  };

  return (
    <div className="h-full max-w-screen-2xl w-5/6 min-h-screen mx-auto  ">
      <div>
        <img src={nav} alt="onemind" className="w-full h-full object-cover" />
        <div>
          <div class="flex flex-col mt-5 md:flex-row md:items-center md:space-x-4 space-y-3 text-white">
            <span class="text-2xl font-bold  text-black">Update News</span>

            <button
              class="bg-primary  hover:bg-black p-3 flex justify-center items-center   md:mb-0"
              onClick={() => setUpdatedNew(true)}
            >
              Update news <FaEdit class="pl-2" size={24} />
            </button>

            <label class="bg-primary  hover:bg-black justify-center  p-3 flex items-center ">
              Upload image
              <input
                type="file"
                accept="image/*"
                class="hidden"
                onChange={handleImageUpload}
              />
              <LuUpload class="pl-2" size={24} />
            </label>

            <button
              class="bg-primary p-3  hover:bg-black  flex justify-center  items-center   md:mb-0"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete news <MdDelete class="pl-2" size={24} />
            </button>

            <button
              class="bg-primary  hover:bg-black p-3 flex justify-center  items-center "
              onClick={(e) => {
                handlePublish(e);
              }}
            >
              Publish news <LuUpload class="pl-2" size={23} />
            </button>
            {isDialogVisibleSucess && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto w-84 border border-primary  rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  <div className="rounded-sm p-4">
                    <p className="text-center text-1xl font-bold text-black mt-9">
                      News published successfully
                    </p>
                    <div className="flex justify-center items-center h-14 mt-5">
                      <img
                        src={check}
                        alt="checkout"
                        className="h-full w-auto object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={`w-full mt-5 bg-lightgray border border-gray flex flex-col md:flex-row p-4 ${
              updatedNew === false ? "md:h-72" : "md:h-auto"
            } `}
          >
            {updatedNew === false ? (
              <div className="w-full md:w-[70%] mt-6 p-2">
                <h1>Title</h1>
                <p>Description</p>
              </div>
            ) : (
              <div className="w-full md:w-[70%] mt-6 p-2">
                <input
                  type="text"
                  placeholder="Title*"
                  className="bg-lightgray outline-none w-full cursor-text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Location*"
                  className="bg-lightgray outline-none w-full cursor-text mt-2"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                {/* <input
                  type="datetime-local"
                  placeholder="Date*"
                  className="bg-lightgray outline-none w-full cursor-text mt-2 text-gray"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                /> */}

                <p>
                  <textarea
                    placeholder="Description*"
                    className="bg-lightgray outline-none w-full h-28 cursor-text mt-2 scrollbar-custom"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </p>
                <input
                  type="text"
                  placeholder="Published by"
                  className="bg-lightgray outline-none w-full mb-0 cursor-text"
                  value={publishedBy}
                  onChange={(e) => setPublishedBy(e.target.value)}
                />
              </div>
            )}

            <div className="w-full md:w-[50%] bg-gray-light p-2 md:ml-8 md:h-auto mt-4">
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Uploaded"
                  className="w-full h-full object-cover"
                />
              ) : (
                <p className="text-center text-lg">Upload the image</p>
              )}
            </div>
          </div>
          {isDialogVisibleDeleteSucess && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-48 w-84 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                <p className="text-center text-1xl font-bold text-black mt-9">
                  News Deleted successfully
                </p>
                <div className="flex justify-center items-center h-14 mt-5">
                  <img
                    src={check}
                    alt="checkout"
                    className="h-full w-auto object-cover"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateNewspage;
