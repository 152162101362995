import React, { useContext, useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { BsArrowLeft } from "react-icons/bs";
import { getAllClassification } from "../../services/category";
import { getLocation } from "../../services/location";
import { useNavigate } from "react-router-dom";
import { bussinessOnboard } from "../../services/admin";
import UserContext from "../context provider/UserContext";
import { me } from "../../services/user";

const BusinessOnboard = () => {
  const navigate = useNavigate();
  const [showLocationsDropdown, setShowLocationsDropdown] = useState(false);
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [nameError, setNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const divRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    me()
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log("ERR", error);
        navigate("/Login");
      });
  }, []);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const validateInputs = () => {
    let isValid = true;
    if (!name) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!businessName) {
      setBusinessNameError("Business name is required.");
      isValid = false;
    } else {
      setBusinessNameError("");
    }
    if (!mobile) {
      setMobileError("Phone number is required.");
      isValid = false;
    } else {
      setMobileError("");
    }
    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else {
      setPasswordError("");
    }
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else {
      setEmailError("");
    }
    return isValid;
  };

  useEffect(() => {
    getAllClassification().then((res) => {
      setCategories(res.data);
    });
    getLocation().then((res) => {
      setLocations(res.data);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowCategoriesDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowCategoriesDropdown(false);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleLocationsClick = () => {
    setShowLocationsDropdown(!showLocationsDropdown);
    setShowCategoriesDropdown(false);
  };

  const handleCategoriesClick = () => {
    setShowCategoriesDropdown(!showCategoriesDropdown);
    setShowLocationsDropdown(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    // Check if all required fields are filled
    if (
      !name ||
      !businessName ||
      !mobile ||
      !email ||
      !password ||
      !selectedCategory ||
      !selectedLocation
    ) {
      alert("Please fill in all required fields before save");
      return;
    }

    bussinessOnboard(
      name,
      businessName,
      mobile,
      email,
      password,
      selectedCategory.id,
      selectedLocation.id
    )
      .then((res) => {
        navigate(-1);
      })
      .catch((error) => {
        console.log("Error:", error);
        alert(error.response.data.message);
        console.log("test");
      });
  };

  return (
    <div className="max-w-screen-2xl w-4/6   mx-auto">
      <div className="bg-lightgray min-h-[560px] lg:h-[540px] md:h-auto rounded-sm  md:mb-5">
        <div className="font-bold text-xl ml-16  pt-8 ">
          <h1 className="text-primary">
            Business<span className="text-black"> Onboard</span>
          </h1>
        </div>
        <div className="flex justify-center">
          <div className="bg-white border border-primary w-full lg:w-[85%] mt-4 p-4  h-auto lg:p-8">
            <div className="flex flex-col md:flex-row items-center mb-4">
              <h1 className="mr-4 md:w-[20%]">Full name</h1>
              <input
                type="text"
                className={`flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none ${
                  !name && "border"
                }`}
                placeholder="Enter your full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {nameError && <p className="text-red text-center">{nameError}</p>}
            <div className="flex flex-col md:flex-row items-center mb-4">
              <h1 className="mr-4 md:w-[20%]">Business Name</h1>
              <input
                type="text"
                className={`flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none ${
                  !mobile && "border"
                }`}
                placeholder="Enter your business  name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            {mobileError && (
              <p className="text-red text-center">{mobileError}</p>
            )}
            <div className="flex flex-col md:flex-row items-center mb-4">
              <h1 className="mr-4 md:w-[20%]">Phone number</h1>
              <input
                type="text"
                className={`flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none ${
                  !mobile && "border"
                }`}
                placeholder="Enter your phone number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            {mobileError && (
              <p className="text-red text-center">{mobileError}</p>
            )}
            <div className="flex flex-col md:flex-row items-center mb-4">
              <h1 className="mr-4 md:w-[20%]">Email Address</h1>
              <input
                type="text"
                className={`flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none ${
                  !email && "border"
                }`}
                placeholder="Enter your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {emailError && <p className="text-red text-center">{emailError}</p>}
            <div className="flex flex-col md:flex-row items-center mb-4">
              <h1 className="mr-4 md:w-[20%]">Password</h1>
              <div className="relative w-full md:w-[80%]">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className={`flex justify-center items-center border border-gray p-2 rounded-md relative w-full bg-lightgray outline-none${
                    passwordError ? " border" : ""
                  }`}
                  value={password}
                  placeholder="Enter Password *"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 text-sm text-gray cursor-pointer"
                  onClick={handlePasswordVisibilityToggle}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            {passwordError && (
              <p className="text-red text-sm text-center">{passwordError}</p>
            )}

            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 ml-0 md:ml-14 w-6/6 justify-center">
              <div className="relative">
                <div
                  className="mt-2 md:mt-0 px-6 md:px-12 py-2 bg-lightgray cursor-pointer relative"
                  onClick={() => {
                    setShowLocationsDropdown(!showLocationsDropdown);
                    setShowCategoriesDropdown(false);
                  }}
                >
                  <h1>
                    {selectedLocation
                      ? selectedLocation.name
                      : "Select Location"}
                  </h1>
                  {showLocationsDropdown ? (
                    <FaChevronUp className="absolute right-4 top-3 cursor-pointer" />
                  ) : (
                    <FaChevronDown
                      className="absolute right-4 top-3 cursor-pointer"
                      onClick={handleLocationsClick}
                    />
                  )}
                  {showLocationsDropdown && (
                    <div className="absolute bg-white border w-full rounded mt-6  p-2  overflow-y-auto left-0 right-0 mx-auto">
                      {locations.map((location, index) => (
                        <div
                          key={index}
                          className="flex items-center p-2 cursor-pointer hover:bg-primary text-gray  hover:text-white transition duration-300"
                          onClick={() => setSelectedLocation(location)}
                        >
                          {location.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="relative" ref={divRef}>
                <div
                  className={` ${
                    showCategoriesDropdown === true ? "md:px-0.5" : "md:px-12"
                  }  py-2 bg-lightgray border border-lightgray hover:border-primary  ${
                    showCategoriesDropdown === true
                      ? " cursor-text"
                      : "cursor-pointer"
                  } relative flex ${
                    showCategoriesDropdown === true
                      ? ""
                      : "justify-center items-center"
                  } `}
                >
                  {showCategoriesDropdown && (
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      className="w-[80%] right-5 px-3 bg-lightgray  outline-none "
                    />
                  )}
                  <h1>
                    {showCategoriesDropdown === false
                      ? selectedCategory
                        ? selectedCategory.name
                        : "Select Classification"
                      : ""}
                  </h1>
                  {showCategoriesDropdown ? (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronUp className="absolute right-4 top-3 cursor-pointer" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleCategoriesClick();
                      }}
                    >
                      <FaChevronDown className="absolute right-4 top-3 cursor-pointer" />
                    </button>
                  )}
                </div>
                {showCategoriesDropdown && (
                  <div className="absolute bg-white border border-gray w-full h-36 rounded mt-4 p-2 max-h-40 overflow-y-auto scrollbar-custom left-0 right-0 mx-auto">
                    {filteredCategories.map((category, index) => (
                      <div
                        key={index}
                        className="flex items-center p-2 cursor-pointer hover:bg-primary text-gray hover:text-white transition duration-300"
                        onClick={() => {
                          handleCategorySelect(category);
                          setShowCategoriesDropdown(false);
                        }}
                      >
                        <div className="w-[100%] ml-3">{category.name}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex space-x-4 justify-end text-white px-14 mt-6">
              <button
                className="bg-lightgray text-black px-4 py-2 rounded-sm"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel{" "}
              </button>
              <button
                className="bg-primary  hover:bg-black px-4 py-2 rounded-sm "
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <button
          className="flex p-2 font-bold mt-4"
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsArrowLeft className="mr-2" size={23} />
          Back
        </button>
      </div>
    </div>
  );
};

export default BusinessOnboard;
