import React, { useState, useEffect, useContext } from "react";
import { MdCall, MdModeEdit } from "react-icons/md";
import { LuRefreshCw, LuUpload } from "react-icons/lu";
import Minidasbord from "./Minidasbord";
import check from "../../assets/Checkmark.png";
import { IoClose } from "react-icons/io5";
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaMapLocationDot,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa6";
import {
  getBusinessDetails,
  updateBusinessProfile,
  deleteFile,
  updateSocialMedia,
} from "../../services/business";
import { IoMdMail } from "react-icons/io";
import { GrMapLocation } from "react-icons/gr";
import { BsPinMapFill } from "react-icons/bs";
import { uploadFile } from "../../services/upload";
import UserContext from "../context provider/UserContext";
import { me } from "../../services/user";
import { useNavigate } from "react-router-dom";

const Businesspage = () => {
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("Business Name");
  const [isBusinessNameEditing, setIsBusinessNameEditing] = useState(false);
  const [overview, setOverview] = useState("");
  const [isOverviewEditing, setIsOverviewEditing] = useState(false);
  const [address, setAddress] = useState("");
  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [isContactEditing, setIsContactEditing] = useState(false);
  const [career, setCareer] = useState("");
  const [isCareerEditing, setIsCareerEditing] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisibleSocialMedia, setIsDialogVisibleSocialMedia] =
    useState(false);
  const [facebookLink, setFacebookLink] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [images, setImages] = useState([]);
  const [firstImage, setFirstImage] = useState("");
  const [map, setMap] = useState("");
  const [id, setId] = useState("");
  const [socialMedia, setSocialMedia] = useState({});
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    me()
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log("ERR", error);
        navigate("/Login");
      });
  }, []);

  useEffect(() => {
    if (user) {
      getBusinessDetails().then((res) => {
        const { socialMedia } = res.data;
        // console.log("rs", res.data);
        setId(res.data.id);
        setBusinessName(res.data.name);
        setAddress(res.data.address ? res.data.address : "");
        setCareer(res.data.career ? res.data.career : "");
        setCategory(res.data.category.name);
        setLocation(res.data.location.name);
        setMobile(res.data.mobile ? res.data.mobile : "");
        setEmail(res.data.email ? res.data.email : "");
        if (socialMedia !== undefined && socialMedia !== null) {
          setFacebookLink(!socialMedia.facebook ? "" : socialMedia.facebook);
          setInstagramLink(!socialMedia.instagram ? "" : socialMedia.instagram);
          setGoogleLink(!socialMedia.google ? "" : socialMedia.google);
          setLinkedIn(!socialMedia.linkedin ? "" : socialMedia.linkedin);
          setSocialMedia(socialMedia ? socialMedia : {});
        }
        const firstObject =
          res.data.images.length > 0 ? res.data.images[0] : "";
        const restObjects =
          res.data.images.length > 0 ? res.data.images.slice(1) : [];
        setFirstImage(firstObject);
        setImages(restObjects);
        setOverview(res.data.overView ? res.data.overView : "");
        setMap(res.data.map ? res.data.map : "");
        setSocialMedia(socialMedia ? socialMedia : {});
      });
    }
  }, [isDialogVisible]);

  useEffect(() => {
    if (user) {
      getBusinessDetails().then((res) => {
        const { socialMedia } = res.data;
        // console.log("rs", res.data);
        if (socialMedia !== undefined && socialMedia !== null) {
          setFacebookLink(!socialMedia.facebook ? "" : socialMedia.facebook);
          setInstagramLink(!socialMedia.instagram ? "" : socialMedia.instagram);
          setGoogleLink(!socialMedia.google ? "" : socialMedia.google);
          setLinkedIn(!socialMedia.linkedin ? "" : socialMedia.linkedin);
          setSocialMedia(socialMedia ? socialMedia : {});
        }
      });
    }
  }, [isDialogVisibleSocialMedia]);

  const handleOpenSocialMediaDialog = () => {
    setIsDialogVisibleSocialMedia(true);
  };

  const handleCloseSocialMediaDialog = () => {
    setIsDialogVisibleSocialMedia(false);
    setFacebookLink(socialMedia?.facebook);
    setInstagramLink(socialMedia?.instagram);
    setGoogleLink(socialMedia?.google);
    setLinkedIn(socialMedia?.linkedin);
  };

  // console.log("socila", facebookLink, instagramLink, googleLink, linkedin);
  const handleSaveSocialMediaLinks = () => {
    const socialMediaLinks = {
      facebook: facebookLink,
      instagram: instagramLink,
      google: googleLink,
      linkedin: linkedin,
    };
    setSocialMedia(socialMediaLinks);
    updateSocialMedia(id, socialMediaLinks)
      .then((res) => {
        handleCloseSocialMediaDialog();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleToggleEdit = (section) => {
    switch (section) {
      case "businessName":
        setIsBusinessNameEditing(!isBusinessNameEditing);
        break;
      case "overview":
        setIsOverviewEditing(!isOverviewEditing);
        break;
      case "address":
        setIsAddressEditing(!isAddressEditing);
        break;
      case "contact":
        setIsContactEditing(!isContactEditing);
        break;
      case "career":
        setIsCareerEditing(!isCareerEditing);
        break;
      case "uploadInformation":
        setIsDialogVisible(true);

        break;
      case "uploadSocialMediaLinks":
        setIsDialogVisibleSocialMedia(true);

        break;

      default:
        break;
    }
  };

  const handleInputChange = (section, e) => {
    switch (section) {
      case "overview":
        setOverview(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "map":
        setMap(e.target.value);
        break;
      case "mobile":
        setMobile(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "career":
        setCareer(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleDeleteImage = (first, index) => {
    if (first === true) {
      deleteFile(firstImage.key).then((res) => {
        setFirstImage(images[0]);
        setImages((obj) => obj.slice(1));
      });
    } else {
      const value = images[index];
      try {
        deleteFile(value.key).then((res) => {
          setImages((images) =>
            images.filter((_, i) => {
              if (i !== index) {
                return true;
              }
              return false;
            })
          );
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  useEffect(() => {
    // Update firstImage if it's null, undefined, or has a length of 0
    if (!firstImage || firstImage.length === 0) {
      if (images.length > 0) {
        setFirstImage(images[0]);
        setImages(images.slice(1)); // Remove the first image from the images array
      }
    }
  }, [images, firstImage]);

  const handleReset = () => {
    setAddress("");
    setCareer("");
    setMobile("");
    setEmail("");
    setFacebookLink("");
    setInstagramLink("");
    setGoogleLink("");
    setLinkedIn("");
    setFirstImage("");
    setImages([]);
    setOverview("");
    setMap("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let allImages = [];

    if (firstImage !== undefined && firstImage !== null) {
      allImages = [firstImage, ...images];
    } else {
      allImages = [...images];
    }

    const uploadPromises = [];

    if (allImages.length > 0 && allImages[0] !== "") {
      for (let i = 0; i < allImages.length; i++) {
        const imageFile = allImages[i];
        if (imageFile.file) {
          uploadPromises.push(
            uploadFile(imageFile.file).then((res) => {
              const { upload } = res.data;
              allImages[i] = { key: upload.key, image: upload.url };
            })
          );
        }
      }
    }

    Promise.all(uploadPromises).then(() => {
      updateBusinessProfile(
        id,
        address,
        mobile,
        email,
        overview,
        career,
        socialMedia,
        allImages,
        map
      ).then((res) => {
        ["businessName", "overview", "address", "contact", "career"].forEach(
          (section) => {
            handleToggleEdit(section);
          }
        );
        setIsDialogVisible(true);
        setTimeout(() => {
          setIsDialogVisible(false);
        }, 3000);
      });
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (images.length < 4) {
      if (file) {
        // Upload file logic
        const image = URL.createObjectURL(file);
        // console.log("file", file);
        setImages((prevImages) => [...prevImages, { image, file }]);
      }
    } else {
      alert("You can only upload up to five images.");
    }
  };

  return (
    <div className="max-w-screen-2xl w-4/5 md:px-12 mx-auto ">
      <div className="flex flex-col md:flex-row md:items-center mt-44 ">
        <div className="p-2   font-bold">
          <h1 className="md:text-center text-center">{businessName}</h1>
        </div>

        <div className="flex flex-col md:flex-row  md:space-x-6    text-white md:space-y-0 space-y-2  md:ml-7">
          <span className="bg-primary  md:text-center text-center rounded-full font-inter text-sm p-2  px-4">
            {location}
          </span>
          <span className="bg-primary md:text-center text-center  rounded-full font-inter text-sm p-2  px-4">
            {category}
          </span>

          <label className="bg-primary inline-flex items-center md:text-center justify-center md:px-8 text-center md:p-0 p-2 cursor-pointer font-inter text-sm rounded-lg text-white">
            <LuUpload className="mr-1" size={14} />
            <span>Upload Photo</span>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </label>
        </div>
      </div>
      <div className="sm:w-full p-2">
        <div className="flex flex-col sm:flex-row space-x-2 mb-2  ">
          {firstImage !== null &&
          firstImage !== undefined &&
          firstImage.length !== 0 ? (
            <div className="w-full md:w-1/2 h-[auto] bg-lightgray">
              <div className="relative group">
                <img
                  src={firstImage.image}
                  alt="Uploaded"
                  className="w-full h-[290px]  p-2  object-fit transition-transform transform group-hover:blur-sm"
                />
                <IoClose
                  className="absolute top-1/2 left-1/2 mb-6 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer text-white rounded-full p-1 m-2 opacity-0 group-hover:opacity-100 transition-opacity"
                  size={36}
                  onClick={() => {
                    handleDeleteImage(true);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="sm:w-1/2 h-72 bg-lightgray mt-1"></div>
          )}
          <div className="w-full md:w-1/2 h-72 grid grid-cols-2 grid-rows-2 gap-2 mb-1">
            {/* Render the images */}
            {images.map((pic, index) => (
              <div
                key={index}
                className="relative group row-span-1 col-span-1 bg-lightgray p-2"
                style={{
                  width: "100%", // Set the width of the div
                  height: "100%", // Set the height of the div
                  overflow: "hidden", // Hide any overflow from the image
                }}
              >
                <img
                  src={pic.image}
                  alt="Uploaded"
                  className="w-full h-full object-fit  transition-transform transform group-hover:blur-sm"
                />
                <IoClose
                  className="absolute top-1/2 left-1/2 mb-6 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer text-white rounded-full p-1 m-2 opacity-0 group-hover:opacity-100 transition-opacity"
                  size={36}
                  onClick={() => {
                    handleDeleteImage(false, index);
                  }}
                />
              </div>
            ))}
            {/* Render default images if the number of images is less than four */}
            {Array.from({ length: Math.max(4 - images.length, 0) }).map(
              (_, index) => (
                <div
                  key={`default-${index}`}
                  className="row-span-1 col-span-1 bg-lightgray p-2"
                ></div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="w-6/6 p-2 ">
        <div className="border h-80 text-black p-2">
          {isOverviewEditing ? (
            <>
              <label
                htmlFor="overview"
                className="mb-2 text-sm font-bold  p-2 text-1xl cursor-pointer"
              >
                Overview
              </label>
              <textarea
                id="overview"
                rows="4"
                value={overview}
                onChange={(e) => handleInputChange("overview", e)}
                className="block p-2.5 w-full h-56 text-sm outline-none rounded-lg scrollbar-custom"
                placeholder="Enter details of your business here...."
              ></textarea>
            </>
          ) : (
            <>
              <label
                htmlFor="overview"
                className=" text-sm font-medium flex p-2 text-1xl cursor-pointer"
                onClick={() => handleToggleEdit("overview")}
              >
                Overview <MdModeEdit className="pl-2" size={23} />
              </label>
              <p className=" px-4 text-justify text-sm h-64 overflow-auto scrollbar-custom">
                {overview}
              </p>
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 mt-3">
          <div className="border h-80 w-full md:w-1/2 text-black p-2">
            {isAddressEditing ? (
              <>
                <label
                  htmlFor="address"
                  className="mb-2 text-sm  font-bold   p-2 text-1xl cursor-pointer  "
                >
                  Address
                </label>
                <textarea
                  id="address"
                  rows="4"
                  value={address}
                  onChange={(e) => handleInputChange("address", e)}
                  className="block p-2.5 w-full text-sm outline-none rounded-lg mt-3"
                  placeholder="Enter address here..."
                ></textarea>
                <div className="flex">
                  <span className="bg-primary w-8 h-8 mt-1 flex items-center justify-center rounded-full p-2">
                    <GrMapLocation className="text-white" size={18} />
                  </span>
                  <input
                    id="map"
                    type="text"
                    value={map}
                    onChange={(e) => handleInputChange("map", e)}
                    className="block p-2.5 w-full text-sm outline-none rounded-lg"
                    placeholder="Enter Google map location/url"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex   justify-between">
                  <label
                    htmlFor="address"
                    className="mb-2 text-sm  font-bold flex p-2 text-1xl cursor-pointer mt-3"
                    onClick={() => handleToggleEdit("address")}
                  >
                    Address <MdModeEdit className="pl-2" size={23} />
                  </label>
                  <span className="flex mt-4">
                    <FaMapLocationDot className="pl-2" size={23} />{" "}
                    <a className="text-primary pl-2" href={map}>
                      Map
                    </a>
                  </span>
                </div>

                <p className=" px-4 text-justify text-sm">{address}</p>
              </>
            )}

            {isContactEditing ? (
              <>
                <label className="text-sm  font-bold flex p-2 text-1xl cursor-pointer mt-3">
                  Contact
                </label>
                <div className="px-2">
                  <div className="flex">
                    <span className="bg-primary w-8 h-8 mt-1 flex items-center justify-center rounded-full p-2">
                      <MdCall className="text-white" size={18} />
                    </span>
                    <input
                      id="mobile"
                      type="text"
                      value={mobile}
                      onChange={(e) => handleInputChange("mobile", e)}
                      className="block p-2.5 w-full bg-white text-sm outline-none rounded-lg"
                      placeholder="Enter your mobilenumber"
                    />
                  </div>

                  <div className="flex">
                    <span className="bg-primary w-8 mt-1 h-8  flex items-center justify-center rounded-full p-2">
                      <IoMdMail className="text-white" size={18} />
                    </span>
                    <input
                      id="email"
                      type="text"
                      value={email}
                      onChange={(e) => handleInputChange("email", e)}
                      className="block p-2.5 w-full text-sm bg-white  outline-none rounded-lg"
                      placeholder="Enter your mail id "
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="contact"
                  className="mb-2 text-sm  font-bold flex p-2 text-1xl cursor-pointer mt-3"
                  onClick={() => handleToggleEdit("contact")}
                >
                  Contact <MdModeEdit className="pl-2" size={23} />
                </label>
                <p className="px-4 text-sm">
                  Call us at {mobile} Email us at {email}
                </p>
              </>
            )}
          </div>
          <div className="border h-80 w-full md:w-1/2 text-black p-2">
            <label
              htmlFor="contact"
              className="mb-2 text-sm font-bold  flex p-2 text-1xl cursor-pointer mt-3"
              onClick={() => handleToggleEdit("uploadSocialMediaLinks")}
            >
              Follow us on Social Media!
              <MdModeEdit className="pl-2" size={23} />
            </label>
            <div className="flex px-4 space-x-2">
              {facebookLink !== undefined && facebookLink.length > 0 && (
                <span>
                  <FaFacebook
                    className="icon block hover:text-gray"
                    size={24}
                  />
                </span>
              )}
              {googleLink !== undefined && googleLink.length > 0 && (
                <span>
                  <FaGoogle className="icon block hover:text-gray" size={24} />
                </span>
              )}
              {linkedin !== undefined && linkedin.length > 0 && (
                <span>
                  <FaLinkedin
                    className="icon block hover:text-gray"
                    size={24}
                  />
                </span>
              )}
              {instagramLink !== undefined && instagramLink.length > 0 && (
                <span>
                  <FaInstagram
                    className="icon block hover:text-gray"
                    size={24}
                  />
                </span>
              )}
            </div>
            {isDialogVisibleSocialMedia && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-primary shadow-red p-4 h-auto sm:w-2/3 md:w-1/2 lg:w-1/3 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  {/* <div className="border border-primary rounded-sm p-4"> */}
                  <p className="text-center text-xl font-bold text-black mt-4 md:mt-9">
                    Social Media Links
                  </p>
                  <div className="mt-4 pl-4">
                    <div className="flex items-center mb-2 w-full">
                      <FaFacebook
                        className="icon block hover:text-gray"
                        size={24}
                      />
                      <input
                        type="text"
                        placeholder="https://www.facebook.com"
                        className="ml-4 border-b  border-gray  w-full focus:outline-none"
                        value={facebookLink}
                        onChange={(e) => setFacebookLink(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center mb-2">
                      <FaGoogle
                        className="icon block hover:text-gray"
                        size={24}
                      />
                      <input
                        type="text"
                        placeholder="https://www.website.com"
                        className="ml-4 border-b border-gray   w-full focus:outline-none"
                        value={googleLink}
                        onChange={(e) => setGoogleLink(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center mb-2">
                      <FaLinkedin
                        className="icon block hover:text-gray"
                        size={24}
                      />
                      <input
                        type="text"
                        placeholder="https://www.linkedin.com"
                        className="ml-4 border-b border-gray   w-full focus:outline-none"
                        value={linkedin}
                        onChange={(e) => setLinkedIn(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center mb-2">
                      <FaInstagram
                        className="icon block hover:text-gray"
                        size={24}
                      />
                      <input
                        type="text"
                        placeholder="https://www.instagram.com"
                        className="ml-4 border-b border-gray   w-full  focus:outline-none"
                        value={instagramLink}
                        onChange={(e) => setInstagramLink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-primary hover:bg-black  text-white p-2 mr-2"
                      onClick={() => {
                        handleSaveSocialMediaLinks();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="bg-lightgray text-black p-2"
                      onClick={() => {
                        handleCloseSocialMediaDialog();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
            {isCareerEditing ? (
              <>
                <label
                  htmlFor="career"
                  className="mb-2 text-sm font-bold   p-2 text-1xl cursor-pointer mt-8"
                >
                  Career
                </label>
                <textarea
                  id="career"
                  rows="4"
                  value={career}
                  onChange={(e) => handleInputChange("career", e)}
                  className="block p-2.5 w-full text-sm outline-none rounded-lg mt-3"
                  placeholder="Enter Details about career opportunities available here..."
                ></textarea>
              </>
            ) : (
              <>
                <label
                  htmlFor="career"
                  className="mb-2 text-sm font-bold flex p-2 text-1xl cursor-pointer mt-3"
                  onClick={() => handleToggleEdit("career")}
                >
                  Career <MdModeEdit className="pl-2" size={23} />
                </label>{" "}
                <p className="px-4 text-sm">{career}</p>
              </>
            )}
          </div>
        </div>

        <div className="flex text-white justify-end space-x-3 mt-2">
          <button
            className="bg-primary hover:bg-black  p-1 h-8 flex"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Upload information <LuUpload className="pl-2" size={23} />
          </button>
          {isDialogVisible === true && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto w-82 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                {/* <div className="border border-primary rounded-sm p-4"> */}
                <p className="text-center text-1xl font-bold text-black mt-9">
                  Business information uploaded successfully.
                </p>
                <div className="flex justify-center items-center h-14 mt-5">
                  <img
                    src={check}
                    alt="checkout"
                    className="h-full w-auto object-cover"
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          )}
          {/* {isDialogVisible && (  
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-md shadow-md backdrop-filter backdrop-blur-lg">
              <p className="text-center text-gray">
                Business information uploaded successfully.
              </p>
              <button
                className="bg-primary text-white p-2 mt-2"
                onClick={handleCloseDialog}
              >
                Close
              </button>
            </div>
          )} */}
          <button
            className="bg-primary   hover:bg-black  p-1 h-8 flex"
            onClick={() => {
              handleReset();
            }}
          >
            Reset information <LuRefreshCw className="pl-2" size={23} />
          </button>
        </div>

        <Minidasbord />
      </div>
    </div>
  );
};

export default Businesspage;
