import React, { useState } from "react";
import entrovert from "../assets/Entreovert-logo.png";
import { IoMdMail } from "react-icons/io";
import { LuUpload } from "react-icons/lu";
import { IoCall } from "react-icons/io5";
import { MdMail } from "react-icons/md";
import check from "../assets/Checkmark.png";
import { postEnquiry } from "../services/entreovert";

const Entreovert = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [businessDescription, setBusinessDescription] = useState("");
  const [exceedLimit, setExceedLimit] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [describe, setDescribe] = useState("");
  const [file, setFile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [describeError, setDescribeError] = useState("");

  const validateInputs = () => {
    let isValid = true;
    if (!describe) {
      setDescribeError("Description is required.");
      isValid = false;
    } else {
      setDescribeError("");
    }
    if (!mobile) {
      setMobileError("Mobile number is required.");
      isValid = false;
    } else {
      setMobileError("");
    }
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!name) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }
    return isValid;
  };

  const handleOpenEnquire = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    const isValid = validateInputs();
    if (isValid) {
      postEnquiry(name, email, mobile, describe, file)
        .then((res) => {
          setIsSubmitted(true);
          setTimeout(() => {
            setIsDialogOpen(false);
            setIsSubmitted(false);
          }, 3000);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  function handleFileSelect(event) {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop();
    const allowedExtensions = ["ppt", "pptx"];

    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      alert("Please upload a file with .ppt or .pptx extension only.");
      event.target.value = "";
      setFileName("");
      setFile("");
      return;
    }
    console.log("selec", file);
    setFile(file);
    setFileName(fileName);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "mobile") {
      setMobile(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "name") {
      setName(value);
    } else if (name === "businessDescription") {
      const words = value.trim().split(/\s+/);
      if (words.length <= 250) {
        setBusinessDescription(value);
        setDescribe(value);
        setExceedLimit(false);
      } else {
        setExceedLimit(true);
      }
    }
  };

  return (
    <div className="bg-lightgray max-w-screen-2xl mx-auto p-10">
      <div className="md:px-14 h-36  flex justify-center  text-black">
        <div className="w-full md:w-5/6">
          <div>
            <h2 className="p-2 text-xl md:text-4xl font-semibold font-poppins text-center">
              Do you have a business idea?
            </h2>
            <p className="p-2 px-4 md:px-0    md:text-sm text-para  font-inter text-center">
              Reach out to our Innovation and Startup Outreach Partner.
            </p>
            <div className="flex justify-center items-center">
              <button
                className="bg-primary hover:bg-black mt-4 text-white font-poppins flex p-2"
                onClick={handleOpenEnquire}
              >
                Enquire Now
                <IoMdMail className="pl-1" size={23} />
              </button>
              <div
                className={`fixed top-0 left-0  md:w-full h-full mt-10 flex items-center justify-center z-50 ${
                  isDialogOpen ? "block" : "hidden"
                }`}
              >
                <div className="bg-white md:w-full  bg-opacity-80 absolute inset-0 "></div>
                <div className="bg-lightgray p-4 w-5/6 md:w-[70%] rounded-md shadow-md relative z-10">
                  <div className=" flex justify-between">
                    <div className="flex flex-col md:flex-row items-center md:ml-2 justify-start">
                      <img
                        src={entrovert}
                        alt="entrovert"
                        className="h-16 object-cover md:mr-4"
                      />
                      <h2 className="text-black font-bold text-3xl md:text-2xl pl-0 md:pl-5 md:ml-4 text-center md:text-left">
                        Entrovert People Association
                      </h2>
                    </div>
                    <div className="  mt-4 md:mt-0">
                      <div className="flex items-center">
                        <IoCall className="md:text-xl text-sm mr-2" />
                        <p>+918148783128</p>
                      </div>
                      <div className="flex items-center">
                        <MdMail className=" md:text-xl text-sm mr-2" />
                        <p>entreovert@gmail.com</p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white border border-primary w-full md:w-[95%] mt-4 p-4 rounded-md overflow-y-auto  scrollbar-custom max-h-[50vh]">
                    <div className="flex flex-col md:flex-row items-center mb-4">
                      <h3 className="mr-4 md:w-[20%]">Full name*</h3>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                        placeholder="Enter your full name"
                      />
                    </div>
                    {nameError && (
                      <p className="text-red flex justify-center -mt-3 ">
                        {nameError}
                      </p>
                    )}

                    <div className="flex flex-col md:flex-row items-center mb-4">
                      <h3 className="mr-4 md:w-[20%]">Email address*</h3>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                        placeholder="Enter your email address"
                      />
                    </div>

                    {emailError && (
                      <p className="text-red flex justify-center -mt-3 ">
                        {emailError}
                      </p>
                    )}
                    <div className="flex flex-col md:flex-row items-center mb-4">
                      <h3 className="mr-4 md:w-[20%]">Phone number*</h3>
                      <input
                        type="tel"
                        name="mobile"
                        value={mobile}
                        onChange={handleChange}
                        className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
                        placeholder="Enter your phone number"
                      />
                    </div>

                    {mobileError && (
                      <p className="text-red flex justify-center -mt-3 ">
                        {mobileError}
                      </p>
                    )}
                    <div
                      className={`flex flex-col md:flex-row items-center mb-4 ${
                        exceedLimit ? "border border-red" : ""
                      }`}
                    >
                      <h3 className="mr-4 md:w-[20%]">
                        Description of your business idea*
                      </h3>
                      <textarea
                        name="businessDescription"
                        value={businessDescription}
                        onChange={handleChange}
                        className={`flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] h-24 bg-lightgray outline-none resize-none ${
                          exceedLimit ? "bg-red" : ""
                        }`}
                        placeholder="Write your business idea with a maximum of 250 words"
                      ></textarea>
                    </div>

                    {describeError && (
                      <p className="text-red flex justify-center -mt-3 ">
                        {describeError}
                      </p>
                    )}
                    <div className="flex flex-col md:flex-row items-center mb-4">
                      <h3 className="mr-4 md:w-[20%]"> Upload Pitch deck</h3>
                      <div className="relative w-full md:w-[80%]">
                        <input
                          type="file"
                          id="pitchDeckInput"
                          accept=".ppt, .pptx"
                          className="hidden"
                          onChange={handleFileSelect}
                        />
                        <label
                          htmlFor="pitchDeckInput"
                          className="flex justify-between items-center border border-gray p-2 rounded-md w-full bg-lightgray cursor-pointer"
                        >
                          <h3 className={fileName ? "text-black" : "text-gray"}>
                            {fileName
                              ? fileName
                              : "Only ppt and pptx files are allowed."}
                          </h3>

                          <LuUpload />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-3 items-center mt-4 pl-0 md:pl-6">
                    <button
                      className="bg-gray rounded-sm text-white flex p-2"
                      onClick={handleCloseDialog}
                    >
                      Close
                    </button>
                    <button
                      className="bg-primary rounded-sm hover:bg-black text-white flex p-2 "
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {isSubmitted && (
                      <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-auto md:w-3/6 w-5/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                          <p className="text-2xl flex justify-center">
                            Thank you for providing the necessary details
                          </p>
                          <p className="text-gray text-sm flex justify-center">
                            Our team will be in touch with you shortly.
                          </p>
                          <div className="flex justify-center items-center h-14 ">
                            <img
                              src={check}
                              alt="checkout"
                              className="h-full w-auto object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entreovert;
