import React, { useEffect, useState } from "react";
import { getLiveStream } from "../services/liveStream";
// import live from "../assets/videotest.mp4";

const Livestream = () => {
  const [embbedUrl, setEmbbedUrl] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    getLiveStream().then((res) => {
      // console.log("fdsjkal", res.data);
      // console.log("LAKFDJKLDSA", res.data.url);
      setUrl(res.data.url);
      setEmbbedUrl(res.data.embbedUrl);
    });
  }, []);
  // console.log("afdsjlk", embbedUrl);

  // const embedUrl =
  //   "https://www.youtube.com/embed/ybhtKvcn4Us?si=oZXfCAvbxgFz083_";
  return (
    <div className="min-h-screen"> 
       <div className="bg-lightgray  flex justify-center mb-4 w-3/6 max-w-screen-2xl h-90 mx-auto">
    <div className="  iframe-container">
      <iframe
        title="livestream"
        src={url}
        allowFullScreen
        className="responsive-iframe"
      ></iframe>
    </div>
  </div></div>
  
  );
};

export default Livestream;
