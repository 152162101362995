import api from "../api";

const baseURL = "/api/category";

export const getClassification = () => {
  return api.get(baseURL);
};

export const getAllClassification = () => {
  return api.get(`${baseURL}/all`);
};
