import React from "react";
import logo from "../assets/One_Mind_Market_Black_Logo_with_text.png";

const About = () => {
  return (
    <div id="about">
      <h2 className="flex justify-center bg-primary text-white mt-8 py-3 font-semibold">
        {" "}
        About Us
      </h2>
      <div className="md:px-12 max-w-screen-2xl w-5/6 mt-4 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 mb-8 md:mt-36 md:mb-0 md:pr-4 text-center">
            <img src={logo} alt="logo" className="w-[15%] md:w-[70%] mx-auto" />
          </div>

          <div className="w-full md:w-1/2 md:p-8 lg:p-8 xl:p-8 text-base">
            <p className="leading-loose text-justify font-sans font-medium text-para">
              During the Pandemic, when the whole world was in the challenge of
              survival of Covid-19, The{" "}
              <span className="font-bold"> One Mind Federation </span> has born.
              Since 2019,{" "}
              <span className="font-bold"> One Mind Federation </span> is in
              digital Marketing service among Vellore entrepreneurs andnative
              brands. After the detailed R&D process of the concept of{" "}
              <span className="font-bold"> One Mind Federation </span> we have
              ventured into online media to reach a greater population in the
              name of{" "}
              <a href="https://www.instagram.com/onemindvellore?igsh=ajlhMWFkdHcwczZ5">
                <span className="font-bold"> One Mind Vellore </span>
              </a>
              , which came into existence from, July2023 and we have achieved
              that milestone with 130k plus followers in a short span of 7
              months.
            </p>
            <p className="leading-loose text-justify font-sans font-medium text-para">
              Another Proud moment of our new venture called{" "}
              <span className="font-bold"> One Mind Market </span>
              with the tagline of “SUPPORT NATIVE ENTRPRENUER”. It is the place
              exclusively for the native-born businesses in various
              categorizations. The team will get in touch with the shortlisted
              applications after they are submitted, contingent upon meeting the
              eligibility requirements.
            </p>
            <p className="leading-loose text-justify font-sans font-medium text-para">
              In Our broader thoughts, we are expanding{" "}
              <span className="font-bold"> One Mind Market </span>in each and
              every city in the near future, to support native entrepreneurs in
              their city.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
