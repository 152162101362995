import api from "../api";

const baseURL = "/api/home";

export const search = (category, location) => {
  console.log("lkadfjka", category, location);
  return api.get(`${baseURL}/search`, { params: { category, location } });
};

export const enquiry = (name, email, mobile, enquiry, businessId) => {
  return api.post(`${baseURL}/enquiry`, {
    name,
    email,
    mobile,
    enquiry,
    businessId,
  });
};

export const uploadResume = (file, id) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", id);
  return api.post(`${baseURL}/resumeUpload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getDetails = (id) => {
  return api.get(`${baseURL}/${id}`);
};
