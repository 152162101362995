import api from "../api";

const baseURL = "/api/user";

export const signup = (name, mobile, password) => {
  return api.post(`${baseURL}/signup`, { name, mobile, password });
};

// export const loginWithGoogle = (token) => {
//   return api.post(`${baseURL}/gmail`, { token });
// };

export const login = (mobile, password) => {
  return api.post(`${baseURL}/login`, { mobile, password });
};

export const resetPassword = (mobile) => {
  return api.post(`${baseURL}/resetPassword`, { mobile });
};

export const resendOtp = (id, mobile) => {
  return api.post(`${baseURL}/mobile/resendOTP`, { id, mobile });
};

export const codeVerification = (code, token) => {
  return api.post(`${baseURL}/codeVerification`, { code, token });
};

export const changePassword = (id, password) => {
  return api.post(`${baseURL}/changePassword`, { id, password });
};

export const fetchAuthToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("InvalidRefreshToken");
  }
  return api.post(`${baseURL}/refresh`, { refreshToken });
};

export const me = () => {
  if (localStorage.getItem("authToken")) {
    console.log("hit1");
    return api.get(`${baseURL}/me`);
  } else {
    console.log("hit2");
    return Promise.reject("Auth token not found.");
  }
};
