import React, { useContext, useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/google.svg";
import logo from "../../assets/One_Mind_Market_Black_Logo_with_text.png";
import logmob from "../../assets/Variant_04.png";
import { login } from "../../services/user";
import UserContext from "../context provider/UserContext";
import { useGoogleLogin } from "@react-oauth/google";

const LoginPage = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setUser } = useContext(UserContext);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      // loginWithGoogle(code).then((res) => {
      //   console.log("response", res.data);
      // });
    },
    onError: (errorResponse) => {
      console.error(errorResponse);
    },
  });

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const validateInputs = () => {
    let isValid = true;
    if (!mobile) {
      setMobileError("Mobile number is required.");
      isValid = false;
    } else {
      setMobileError("");
    }
    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };

  const handleRegister = (isGoogleRegistration) => {
    if (isGoogleRegistration === false) {
      if (validateInputs()) {
        console.log("Registering:", {
          mobile,
          password,
          isGoogleRegistration,
        });
        login(mobile, password)
          .then((response) => {
            const { authToken, refreshToken, expiresAt, user } = response.data;
            localStorage.setItem("authToken", authToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("expiresAt", expiresAt);
            setUser(user);
            if (user.type === "user") {
              navigate("/");
            } else if (user.type === "business") {
              navigate("/business");
            } else {
              navigate("/admin");
            }
          })
          .catch((error) => {
            const { response } = error;
            console.log("error", error);
            setPasswordError(response.data.message);
          });
      } else {
        // Don't navigate if inputs are invalid
        console.log("Inputs are invalid.");
      }
    } else {
      googleLogin();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleRegister(false);
    }
  };

  return (
    <div className="h-full max-w-screen-2xl min-h-screen mx-auto w-full flex flex-col md:flex-row">
      <span className="w-full md:w-1/2 bg-lightgray hidden md:inline  items-center justify-center md:p-16 ">
        <img src={logo} alt="Logo" className="w-1/2 md:w-1/2  xl:w-1/2 mx-auto" />
        <h1 className="flex justify-center font-inter mt-16">
          One City! One Classification! One Entrepreneur!
        </h1>
      </span>
      <span>
        <img src={logmob} alt="logo" className="md:hidden w-[auto]" />
      </span>
      <div className="w-full md:w-1/2">
        <div className="md:w-[65%] w-[100%] flex justify-between p-4 md:p-8">
          <Link to="/">
            <h1 className="flex font-semibold">
              <MdOutlineArrowBackIos className="mt-1" />
              Back
            </h1>
          </Link>
          <h1 className="text-sm">
            Don't have an account yet?
            <Link to="/Signup">
              <span className="text-primary font-semibold ml-1">Signup</span>
            </Link>
          </h1>
        </div>
        <div className="w-full md:p-0 p-4 md:w-[60%] md:ml-10">
          <h1 className="text-2xl font-bold mt-4">Login</h1>
          <div className="w-full">
            <form className="space-y-4" onKeyDown={handleKeyDown}>
              <p className="text-sm text-gray pt-3 text-center md:text-left">
                Please enter your login details to access your account
              </p>
              <div className="mb-3">
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium font-inter"
                >
                  Mobile Number*
                </label>
                <input
                  type="mobile"
                  id="mobile"
                  className={`mt-1 p-2 border w-full ${
                    mobileError && "border-red"
                  }`}
                  value={mobile}
                  placeholder="Enter mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
                {mobileError && (
                  <p className="text-red text-sm">{mobileError}</p>
                )}
              </div>
              <div className="mb-3 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium font-inter"
                >
                  Password*
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className={`mt-1 p-2 border w-full ${
                    passwordError && "border-red"
                  }`}
                  value={password}
                  placeholder="Enter Password "
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className={`absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-3 ${
                    passwordError ? "-mt-0.5" : ""
                  } text-gray cursor-pointer`}
                  onClick={handlePasswordVisibilityToggle}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>

                {passwordError && (
                  <p className="text-red text-sm">{passwordError}</p>
                )}
              </div>
              <Link to="/forgotpasswordFlow1">
                <h1 className="text-primary font-semibold font-sans text-right mt-1">
                  Forgot password?
                </h1>
              </Link>

              <button
                type="button"
                className="bg-primary text-white hover:bg-black w-full py-3 mt-4"
                onClick={() => handleRegister(false)}
              >
                Login
              </button>
              <div className="flex items-center text-gray mt-4">
                <hr className="flex-grow border-t border-gray mr-2" />
                OR
                <hr className="flex-grow border-t border-gray ml-2" />
              </div>
              <button
                type="button"
                className="bg-white border text-black flex items-center justify-center w-full py-3 p-2 mt-4"
                onClick={() => handleRegister(true)}
              >
                <img src={google} alt="Google" className="w-10 p-2" />
                <h3 className="pl-4">Login with Google</h3>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
