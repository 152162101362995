import React, { useContext, useEffect, useState } from "react";

import { MdModeEdit } from "react-icons/md";
import check from "../../assets/Checkmark.png";

import UserContext from "../context provider/UserContext";
import {
  deleteAccount,
  removeAvatar,
  updatePassword,
  updateProfile,
} from "../../services/me";
import { me } from "../../services/user";
import { uploadFile } from "../../services/upload";
import { useNavigate } from "react-router-dom";
import { deleteFile } from "../../services/business";

const Profile = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordSucess, setIsPasswordSucess] = useState(false);

  // const {user}=useContext(UserContext)
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [avatarKey, setAvatarKey] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [accountDeleteDialog, toggleAcccountDeleteDialog] = useState(false);
  const [file, setFile] = useState("");
  const { setUser } = useContext(UserContext);
  const [accountUpdate, toggleAccountUpdate] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [nameError, setNameError] = useState("");

  const validateInputs = () => {
    let isValid = true;
    if (!name) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!mobile) {
      setMobileError("Phone number is required.");
      isValid = false;
    } else {
      setMobileError("");
    }
    return isValid;
  };

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    // Delete account logic goes here
    // This is just a placeholder
    deleteAccount().then((res) => {
      console.log("Account deleted!");
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiresAt");
      setUser("");
      handleDeleteDialog();
      navigate("/");
    });
  };

  const handleDeleteDialog = () => {
    toggleAcccountDeleteDialog(true);
    setTimeout(() => {
      toggleAcccountDeleteDialog(false);
      setShowConfirmation(false);
    }, 3000);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    me()
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setMobile(res.data.mobile);
        setAvatarKey(res.data.avatarKey !== null ? res.data.avatarKey : "");
        setAvatarUrl(res.data.avatarUrl !== null ? res.data.avatarUrl : "");
      })
      .catch((error) => {
        navigate("/Login");
      });
  }, [accountUpdate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(URL.createObjectURL(file));
    setFile(file);
    // Show close icon when a new image is uploaded
  };

  const handleRemoveImage = () => {
    setProfileImage(null);
    setAvatarKey("");
    setAvatarUrl("");
    toggleAccountUpdate(!accountUpdate);
    removeAvatar().then((res) => {});
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const sucessDialog = () => {
    setIsPasswordSucess(true);
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    console.log("tes", newPassword, confirmPassword, currentPassword);
    if (String(newPassword) !== String(confirmPassword)) {
      alert("New Password and Confirm Password must same");
      return;
    }
    if (currentPassword === newPassword) {
      alert("Current password and New password must not be same");
      return;
    }
    updatePassword(currentPassword, newPassword)
      .then((res) => {
        handlePasswordDialog();
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        const { response } = error;
        alert(response.data.message);
      });
  };

  const handlePasswordDialog = () => {
    setIsPasswordSucess(true);
    setTimeout(() => {
      setIsPasswordSucess(false);
      closeDialog();
    }, 3000);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    let avatar_url = avatarUrl;
    let avatar_key = avatarKey;
    if (file) {
      const {
        data: {
          upload: { key, url },
        },
      } = await uploadFile(file);
      avatar_url = url;
      avatar_key = key;
      removeAvatar();
    }
    // uploadFile(file)
    //   .then((res) => {
    //     const { key, url } = res.data.upload;
    //     console.log("key", key, url);
    // toggleAccountUpdate(!accountUpdate);

    // removeAvatar().then((res) => {
    updateProfile(name, mobile, email, avatar_key, avatar_url).then((res) => {
      setAvatarKey(avatar_key);
      setAvatarUrl(avatar_url);
      toggleAccountUpdate(!accountUpdate);
      handleSubmit();
    });
    //   });
    // })
    // .then(() => {});
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const handleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleSubmit = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setIsDialogOpen(false);
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <div className="max-w-screen-2xl w-4/6 mx-auto  h-full md:p-6 p-0">
      <div className="flex md:ml-16">
        <div className="bg-lightgray w-24 h-24 flex items-center justify-center rounded-full p-2">
          <img
            src={
              profileImage
                ? profileImage
                : avatarUrl ||
                  "https://oneminassects.s3.ap-south-1.amazonaws.com/profile.png"
            }
            alt="profile"
            className="h-full w-full object-fit rounded-full"
          />
        </div>
        <div className="ml-4 text-left p-4 ">
          <h1 className="font-bold  text-2xl">{name}</h1>
          <p className="text-sm text-gray mb-2 ">{email}</p>
          <input
            id="imageUpload"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          <label
            className="bg-primary  hover:bg-black  hidden md:inline text-white  md:p-2 md:rounded-full cursor-pointer md:text-sm"
            htmlFor="imageUpload"
          >
            Change The Image
          </label>
          {avatarKey && (
            <span
              className="md:ml-4 hidden md:inline cursor-pointer"
              onClick={handleRemoveImage}
            >
              Remove
            </span>
          )}
          <label
            className="bg-primary text-white rounded-full px-3 md:hidden"
            htmlFor="imageUpload"
          >
            Edit
          </label>
          {avatarKey && (
            <span
              className="md:ml-4 md:hidden text-sm cursor-pointer"
              onClick={handleRemoveImage}
            >
              Remove
            </span>
          )}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="bg-white border border-primary w-full lg:w-[85%] mt-4 p-4 lg:p-8">
          <div className="flex flex-col md:flex-row items-center mb-4">
            <h1 className="mr-4 md:w-[20%]">Full name*</h1>
            <input
              type="text"
              className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
              placeholder=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {nameError && <p className="text-red text-center">{nameError}</p>}
          <div className="flex flex-col md:flex-row items-center mb-4">
            <h1 className="mr-4 md:w-[20%]">Phone number*</h1>
            <input
              type="text"
              className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
              placeholder=" "
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          {mobileError && <p className="text-red text-center">{mobileError}</p>}
          <div className="flex flex-col md:flex-row items-center mb-4">
            <h1 className="mr-4 md:w-[20%]">Email address</h1>
            <input
              type="text"
              className="flex justify-center items-center border border-gray p-2 rounded-md w-full md:w-[80%] bg-lightgray outline-none"
              placeholder=" abc@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col md:flex-row items-center mb-4">
            <h1 className="mr-4 md:w-[20%]">Password</h1>

            <div className="relative w-full md:w-[80%]">
              <input
                type="text"
                className="flex justify-center cursor-pointer items-center border border-gray p-2 rounded-md w-full bg-lightgray outline-none"
                placeholder="Change account password"
                readOnly={true}
                onClick={openDialog}
              />
              <MdModeEdit
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={openDialog}
              />
            </div>

            {isDialogOpen && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
                <div className="bg-white w-2/6 p-6 rounded-lg border border-primary shadow-red">
                  <h2 className="text-lg font-bold mb-4">Change Password</h2>
                  <div className="mb-4  relative">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      className="border border-gray bg-lightgray  p-2 rounded-md w-full outline-none"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-1 text-gray cursor-pointer"
                      onClick={handleCurrentPassword}
                    >
                      {showCurrentPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                  <div className="mb-4 relative">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="border border-gray p-2  bg-lightgray rounded-md w-full outline-none"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-1 text-gray cursor-pointer"
                      onClick={handleNewPassword}
                    >
                      {showNewPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                  <div className="mb-4 relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="border border-gray p-2  bg-lightgray rounded-md w-full outline-none"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-1 text-gray cursor-pointer"
                      onClick={handleConfirmPassword}
                    >
                      {showConfirmPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="px-4 py-2 bg-gray text-white rounded-md mr-2"
                      onClick={closeDialog}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-primary  hover:bg-black text-white rounded-md"
                      onClick={(e) => {
                        handleUpdatePassword(e);
                      }}
                    >
                      Save
                    </button>
                    {isPasswordSucess && (
                      <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-56 w-2/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                          <p className="text-2xl flex justify-center mt-14">
                            Password Change Successful! &#x1F512;
                          </p>

                          <div className="flex justify-center items-center h-14 ">
                            <img
                              src={check}
                              alt="checkout"
                              className="h-full w-auto object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <div >
            <button onClick={handleDeleteClick}>
              <div className="text-primary font-bold">Delete account</div>
              </button>
            <p className="text-sm">permanently delete your account</p>
             
            </div>

            {accountDeleteDialog && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-56 md:w-2/6 w-4/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  <p className="text-2xl flex justify-center mt-14">
                    Account deleted successfully
                  </p>

                  <div className="flex justify-center items-center h-14 ">
                    <img
                      src={check}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
            )}
            {showConfirmation && (
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="absolute inset-0 bg-gray opacity-50"></div>
                <div className="bg-white p-8 rounded-lg z-10">
                  <p>Are you sure you want to delete your account?</p>
                  <div className="flex justify-end mt-4">
                    <button
                      className="mr-4 px-4 py-2 bg-primary text-white rounded hover:bg-red"
                      onClick={handleConfirmDelete}
                    >
                      Yes
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-300 text-gray rounded  "
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <button
              className="px-4 py-2 bg-primary  hover:bg-black text-white rounded-md"
              onClick={(e) => {
                handleUpdateProfile(e);
              }}
            >
              Update
            </button>
            {isSubmitted && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 z-50 backdrop-filter backdrop-blur-lg">
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-56 md:w-2/6 w-5/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
                  <p className="text-2xl flex justify-center mt-14">
                    Profile updated successfully!
                  </p>

                  <div className="flex justify-center items-center h-14 ">
                    <img
                      src={check}
                      alt="checkout"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
