import React, { useContext, useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import logo from "../../assets/One_Mind_Market_Black_Logo_with_text.png";
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/google.svg";
import logmob from "../../assets/Variant_04.png";
import { signup } from "../../services/user";
import UserContext from "../context provider/UserContext";
const SignupPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setUser } = useContext(UserContext);
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };
  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };
  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      const passwordErrors = [];
      if (!/(?=.*[a-z])/.test(password)) {
        return "At least one lowercase letter is required.";
      }
      if (password.length < 8) {
        return "Password should at least  be 8 characters long";
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        return "At least one uppercase letter is required.";
      }
      if (!/(?=.*\d)/.test(password)) {
        return "At least one number is required.";
      }
      if (!/(?=.*[@$!%*?&])/.test(password)) {
        return "At least one special character is required.";
      }
      // console.log("Password errors:", passwordErrors.join(" "));
      // setErrors({
      //   ...errors,
      //   password:
      //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
      // });
      return false;
    }
    return true;
  };
  const handleRegister = (isGoogleRegistration) => {
    // Add your logic to handle registration, e.g., API call or validation
    console.log("Registering:", {
      name,
      mobile,
      password,
      isChecked,
      isGoogleRegistration,
    });
    let hasError = false;
    if (!name) {
      setNameError("Name is required");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!mobile) {
      setMobileError("Mobile number is required");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!password) {
      setPasswordError("Password is required");
      hasError = true;
    } else {
      const valid = isPasswordValid(password);
      console.log("valid", valid);
      if (valid !== true) {
        setPasswordError(valid);
        hasError = true;
      } else {
        setPasswordError("");
      }
    }
    if (!isChecked) {
      alert("Please accept Terms & Condition");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    signup(name, mobile, password)
      .then((response) => {
        const { authToken, refreshToken, expiresAt, user } = response.data;
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresAt", expiresAt);
        navigate(-1);
        setUser(user);
      })
      .catch((error) => {
        const { data } = error.response;
        setMobileError(data.message);
        hasError = true;
      });
  };
  return (
    <div className="h-full max-w-screen-2xl min-h-screen mx-auto w-full flex flex-col md:flex-row">
      <span className="w-full md:w-1/2 bg-lightgray hidden md:inline  items-center justify-center md:p-16 ">
        <img src={logo} alt="" className="w-1/2 md:w-1/2  xl:w-1/2 mx-auto" />
        <h1 className="flex justify-center  font-inter mt-16">
          One City! One Classification! One Entrepreneur!
        </h1>
      </span>
      <span>
        <img src={logmob} alt="logo" className="md:hidden" />
      </span>
      <div className="md:w-1/2 ">
        <div className="md:w-[65%] w-[100%]   flex  justify-between p-4 md:p-8">
          <Link to="/">
            <span className="flex font-semibold ">
              <MdOutlineArrowBackIos className="mt-1" />
              Back
            </span>
          </Link>
          <h1 className=" text-sm ">
            Already have an account?{" "}
            <Link to="/Login">
              <span className="text-primary font-semibold ml-1">Login</span>
            </Link>
          </h1>
        </div>
        <div className=" w-full md:w-[60%] md:ml-10 p-4">
          <h1 className="text-2xl font-bold mt-4 ">New User Sign Up</h1>
          <div className="w-full">
            <form className="space-y-4">
              <p className="text-sm text-gray pt-3">
                Please provide your information to set up your account.
              </p>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium  font-inter"
                >
                  Full Name*
                </label>
                <input
                  type="text"
                  id="name"
                  className="mt-1 p-2 border w-full"
                  value={name}
                  placeholder="Enter Your Full Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {nameError && <p className="text-red text-sm">{nameError}</p>}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium  font-inter"
                >
                  Mobile Number*
                </label>
                <input
                  type="tel"
                  id="mobile"
                  className="mt-1 p-2 border w-full"
                  value={mobile}
                  placeholder="Enter Your Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
                {mobileError && (
                  <p className="text-red  text-sm">{mobileError}</p>
                )}
              </div>
              <div className="mb-3 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium font-inter "
                >
                  Password*
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="mt-1 p-2 border w-full"
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className={`absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-3 ${
                    passwordError ? "-mt-0.5" : ""
                  } text-gray cursor-pointer`}
                  onClick={handlePasswordVisibilityToggle}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
                {passwordError && (
                  <p className="text-red text-sm">{passwordError}</p>
                )}
              </div>
              <div className="mb-3 flex items-center">
                <input
                  type="checkbox"
                  id="termsCheckbox"
                  className="mr-2"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  required
                />
                <label
                  htmlFor="termsCheckbox"
                  className="text-sm text-para font-inter"
                >
                  I agree to the Terms and Conditions
                </label>
              </div>
              <button
                type="button"
                className="bg-primary  hover:bg-black text-white w-full font-inter py-3 mt-4"
                onClick={() => handleRegister(false)}
              >
                Register Account
              </button>
              <div className="flex items-center text-gray">
                <hr className="flex-grow border-t border-gray mr-2" />
                OR
                <hr className="flex-grow border-t border-gray ml-2" />
              </div>
              <button
                type="button"
                className="bg-white border text-black font-inter flex justify-center items-center w-full py-3 p-2 "
                onClick={() => handleRegister(true)}
              >
                <img src={google} alt="" className="w-12 p-2" />
                <h3 className="pl-7">Register with Google</h3>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignupPage;
