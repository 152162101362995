import React, { useState } from "react";
import play from "../assets/playstore.svg";
import apple from "../assets/applestore.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// import { FontAwesomeIcon} from "@fortawesome/react-fontawesome ";
import {
  FaFacebook,
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa6";
import { IoMdClose, IoMdMail } from "react-icons/io";
// import { faFacebook,} from '@fortawesome/free-brands-svg-icons';
// import { FaGoogle } from '@fortawesome/free-brands-svg-icons';
import { MdCall } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import logo from "../assets/One_Mind_Market_Black_Logo_with_text.png";
import check from "../assets/Checkmark.png";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenContact = () => {
    setIsDialogOpen(true);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setIsDialogOpen(false);
      setIsSubmitted(false);
    }, 3000);
  };
  const navItem = [
    { link: "Search", path: "home" },
    { link: "About Us ", path: "about" },
    { link: "News", path: "news" },
  ];
  const test = navItem.filter((item) => {
    if (item.link === "News") {
      return false;
    }
    return true;
  });

  const navigateToHome = (to) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const targetElement = document.getElementById(to);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  };
  return (
    <>
      <div className="bg-black md:px-14 p-4 max-w-screen-2xl mx-auto md:h-[314px] h-[250px] text-white">
        <div className="md:flex hidden ">
          <div className="w-[24%] absolute md:mt-24  mt-28">
            <h2 className="text-center text-lg font-Montserrat  font-semibold">
              Follow Us
            </h2>
            <div className="flex justify-center  items-center space-x-4 mt-2">
                          <a href="https://www.facebook.com/onemindfederation/">
                              <FaFacebookF
                                className="icon block hover:text-gray"
                                size={24}
                              />
                            </a>
                            <a href="https://www.youtube.com/@OneMindVellore">
                              <FaYoutube
                                className="icon block hover:text-gray"
                                size={28}
                              />
                            </a>
                            <a href="https://www.instagram.com/onemindvellore?igsh=ajlhMWFkdHcwczZ5">
                              <FaInstagram
                                className="icon block hover:text-gray"
                                size={24}
                              />
                            </a>
            </div>
            <div className="md:pt-28 pt-2 md:text-sm pl-8 text-inter">
              ©All rights reserved one mind federation{" "}
            </div>
          </div>
          <div className="flex flex-col   relative md:flex-row justify-center mx-auto  items-center w-[40%] md:w-[80%] mt-24  space-y-4 md:space-y-4   ">
            <p className="text-center md:pr-7 cursor-pointer font-Montserrat  pl-56 font-semibold ">
              {navItem.map(({ link, path }) => (
                // Render navigation item conditionally based on renderProfile variable
                <ScrollLink
                  key={path}
                  onClick={() => navigateToHome(path)}
                  to={path}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <span className="block  md:inline mx-2  hover:text-gray  md:pr-16">
                    {link}
                  </span>
                </ScrollLink>
              ))}
              <span
                className="block md:inline mx-2  hover:text-gray  md:pr-16"
                onClick={handleOpenContact}
              >
                Contact
              </span>
              <div
                className={`fixed top-0 left-0 w-full h-full  flex items-center justify-center z-50 ${
                  isDialogOpen ? "block" : "hidden"
                }`}
              >
                <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
                <div className="bg-lightgray mt-4 p-4 w-full md:w-[60%] rounded-md shadow-md relative z-10">
                  <div className="font-bold text-xl pl-3 flex justify-between">
                    <h3 className="text-primary">
                      Contact <span className="text-black">Us</span>
                    </h3>
                    <IoMdClose
                      className="text-black"
                      onClick={handleCloseDialog}
                    />
                  </div>
                  <div className="bg-white border border-primary w-full md:w-[95%] rounded-md ml-4 text-black h-auto max-h-[80vh]">
                    <div className=" md:w-64   p-6 text-center flex">
                      <img src={logo} alt="logo" className="w-full  mx-auto" />
                      <div className="md:flex-row items-center  md:ml-20  space-y-4 md:space-y-5   p-2">
                        <div className="flex items-center">
                          <span className="bg-gray w-13 h-13 flex items-center justify-center rounded-full p-2">
                            <IoMdMail className="text-white" size={28} />
                          </span>
                          <div className="ml-4">
                            <h4 className="font-bold flex justify-start">
                              Email:
                            </h4>
                            <h4 className="text-para">
                              onemindmarketofficial@gmail.com
                            </h4>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <span className="bg-gray w-13 h-13 flex items-center justify-center rounded-full p-2">
                            <MdCall className="text-white" size={28} />
                          </span>
                          <div className="ml-4">
                            <h4 className="font-bold flex justify-start">
                              Contact Number:
                            </h4>
                            <h4 className="text-para">+91 9677690666</h4>
                          </div>
                        </div>
                        <div className=" mx-auto  py-6">
                          <h4 className="text-center text-lg font-bold ">
                            Follow Us
                          </h4>
                          <div className="flex justify-center cursor-pointer items-center text-gray space-x-4 mt-2">
                            <a href="https://www.facebook.com/onemindfederation/">
                              <FaFacebookF
                                className="icon block hover:text-black"
                                size={24}
                              />
                            </a>
                            <a href="https://www.youtube.com/@OneMindVellore">
                              <FaYoutube
                                className="icon block hover:text-black"
                                size={28}
                              />
                            </a>
                            <a href="https://www.instagram.com/onemindvellore?igsh=ajlhMWFkdHcwczZ5">
                              <FaInstagram
                                className="icon block hover:text-black"
                                size={24}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div className="flex  ">
            <div className="md:w-[100%] w-[30%] md:mr-24  text-end  mt-[-90px] md:mt-16">
              <a href="https://play.google.com/store/apps/details?id=com.onemindmarket ">
                <img src={play} alt="play" className="w-36 h-10 object-cover" />
              </a>
              <a href="https://apps.apple.com/app/one-mind-market/id6504680708">
              <img
                src={apple}
                alt="apple"
                className="  md:w-32 mt-2 ml-2 object-cover h-7"
              />
              </a>
            </div>
          </div>
        </div>
        <div className="md:hidden flex justify-around ">
          <div>
            <p className=" cursor-pointer font-serif  font-semibold ">
              {navItem.map(({ link, path }) => (
                // Render navigation item conditionally based on renderProfile variable
                <ScrollLink
                  key={path}
                  onClick={() => navigateToHome(path)}
                  to={path}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <span className="block  md:inline mx-2  hover:text-gray  md:pr-16">
                    {link}
                  </span>
                </ScrollLink>
              ))}
              <span
                className="block md:inline mx-2  hover:text-gray  md:pr-16"
                onClick={handleOpenContact}
              >
                Contact
              </span>
              <div
                className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 ${
                  isDialogOpen ? "block" : "hidden"
                }`}
              >
                <div className="bg-white bg-opacity-80 absolute inset-0 "></div>
                <div className="bg-lightgray mt-4 p-4   w-5/6 rounded-md shadow-md relative z-10">
                  <div className="font-bold text-xl pl-3 flex justify-between">
                    <h3 className="text-primary">
                      Contact <span className="text-black">Us</span>
                    </h3>
                    <IoMdClose
                      className="text-black"
                      onClick={handleCloseDialog}
                    />
                  </div>
                  <div className="bg-white border border-primary w-[90%]   rounded-md ml-4 text-black h-auto max-h-[80vh]">
                    <div className="  text-center flex">
                      {/* <img src={logo} alt="logo" className="w-12 mx-auto" /> */}
                      <div className=" items-center w-[80%]">
                        <div className="flex items-center p-4">
                          <span className="bg-gray w-13 h-13 flex items-center justify-center rounded-full p-2">
                            <IoMdMail className="text-white" size={28} />
                          </span>
                          <div className="ml-2 text-sm">
                            <h4 className="font-bold flex justify-start">
                              Email:
                            </h4>
                            <h4 className="text-para text-xs">
                              onemindvellore@gmail.com
                            </h4>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <span className="bg-gray w-13 h-13 flex items-center ml-2 justify-center rounded-full p-2">
                            <MdCall className="text-white" size={28} />
                          </span>
                          <div className="ml-2">
                            <h4 className="font-bold flex justify-start">
                              Contact Number:
                            </h4>
                            <h4 className="text-para">+91 9677690666</h4>
                          </div>
                        </div>
                        <div className="mx-auto py-6">
                          <h4 className="text-center text-lg font-bold">
                            Follow Us
                          </h4>
                          <div className="flex justify-center cursor-pointer items-center text-gray space-x-4 mt-2">
                            <a href="https://www.facebook.com/onemindfederation/">
                              <FaFacebookF
                                className="icon block hover:text-black"
                                size={24}
                              />
                            </a>
                            <a href="https://www.youtube.com/@OneMindVellore">
                              <FaYoutube
                                className="icon block hover:text-black"
                                size={28}
                              />
                            </a>
                            <a href="https://www.instagram.com/onemindvellore?igsh=ajlhMWFkdHcwczZ5">
                              <FaInstagram
                                className="icon block hover:text-black"
                                size={24}
                              />
                            </a>
                            <img
                              src={logo}
                              alt="logo"
                              className="w-12 mx-auto"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div className="flex  ">
            <div className=" ">
              <a href="https://play.google.com/store/apps/details?id=com.onemindmarket ">
                <img src={play} alt="play" className="w-36 h-10 object-cover" />
              </a>
              <a href="https://apps.apple.com/app/one-mind-market/id6504680708">
              <img
                src={apple}
                alt="apple"
                className="  md:w-32 mt-2 ml-2 object-cover h-10"
              />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className=" text-centr md:hidden ">
            <h3 className="text-center text-lg font-inter  font-semibold">
              Follow Us
            </h3>
            <div className="flex justify-center cursor-pointer items-center space-x-4 mt-2">
              <a href="https://www.facebook.com/onemindfederation/">
                <FaFacebookF className="icon block hover:text-gray" size={24} />
              </a>
              <a href="https://www.youtube.com/@OneMindVellore">
                <FaYoutube className="icon block hover:text-gray" size={28} />
              </a>
              <a href="https://www.instagram.com/onemindvellore?igsh=ajlhMWFkdHcwczZ5">
                <FaInstagram className="icon block hover:text-gray" size={24} />
              </a>
            </div>
            <div class="text-center  text-xs">
              <p class="md:inline-block">
                © All rights reserved one mind federation
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
