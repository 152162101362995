import React, { useContext, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { businessList, deleteBusiness } from "../../services/admin";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import check from "../../assets/Checkmark.png";
import UserContext from "../context provider/UserContext";
import { me } from "../../services/user";

const DashboardPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleteSucess, setIsDeleteSucess] = useState(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    me()
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log("ERR", error);
        navigate("/Login");
      });
  }, []);

  const handleDeleteClick = (item) => {
    setShowConfirmation(true);
    setDeleteId(item.id);
  };
  const handleDeleteDiazlog = () => {
    setIsDeleteSucess(true);

    setTimeout(() => {
      setIsDeleteSucess(false);
      setShowConfirmation(false);
    }, 3000);
  };

  const handleConfirmDelete = () => {
    // Delete account logic goes here
    // This is just a placeholder
    console.log("Account deleted!");
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (user) {
      businessList().then((res) => {
        setData(res.data);
      });
    }
  }, [deleteId]);
  console.log("alkjdsf", data);

  const handleDelete = () => {
    deleteBusiness(deleteId)
      .then((res) => {
        setDeleteId("");
        console.log("hit");
        handleDeleteDiazlog();
      })
      .catch((error) => {
        const { response } = error;
        alert(response.data.message);
      });
  };

  return (
    <div className="px-4 max-w-screen-2xl w-full md:w-5/6 py-10 mx-auto">
      <div className="flex justify-between">
        <h1 className="text-white bg-primary p-2 rounded-full">
          Number of business users{" "}
        </h1>
        <Link to="/BusinessOnboard">
          <button className="text-white bg-primary  hover:bg-black py-2 px-4">
            Business Onboard
          </button>
        </Link>
      </div>
      {data === null || data === undefined || data.length === 0 ? (
        <div className="flex justify-center">
          No business details registered
        </div>
      ) : (
        <div className="table-container mt-4 h-[360px]  scrollbar-custom ">
          <table className="table-body w-full text-sm p-2">
            <thead className=" top-0 bg-white z-10">
              <tr className="border border-gray">
                <th className="py-3 text-center">SNo</th>
                <th className="text-center">Classification</th>
                <th className="text-center">Location</th>
                <th className="text-center">Company Name</th>
                <th className="text-center">Username</th>
                <th className="text-center">Mobile</th>
                <th className="text-center">Enquiry</th>
                <th className="text-center">Job Applied</th>
                <th className="text-center">Joined On</th>
                <th className="text-center">&nbsp;</th>
              </tr>
            </thead>

            <tbody className="h-auto">
              {data.map((item, index) => (
                <tr key={item.id} className="text-center bg-lightgray">
                  {/* {Object.values(item).map((value, index) => (
                  <td key={index} className="py-3">
                    {value}
                  </td>
                ))} */}

                  <td className="py-3 text-center">{index + 1}</td>
                  <td className="text-center">{item.category}</td>
                  <td className="text-center">{item.location}</td>
                  <td className="text-center">{item.name}</td>
                  <td className="text-center">{item.userName}</td>
                  <td className="text-center">{item.mobile}</td>
                  <td className="text-center">{item.enquiry}</td>
                  <td className="text-center">{item.jobsApplied}</td>
                  <td className="text-center">
                    {dayjs(item.createdAt).format("DD/MM/YYYY")}
                  </td>
                  <td className="">
                    <button
                      className="text-black px-2 py-1 ml-2"
                      onClick={() => {
                        handleDeleteClick(item);
                      }}
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray opacity-50"></div>
          <div className="bg-white p-8 rounded-lg z-10">
            <p>Are you sure you want to delete your account?</p>
            <div className="flex justify-end mt-4">
              <button
                className="mr-4 px-4 py-2 bg-primary text-white rounded hover:bg-red"
                onClick={() => {
                  handleDelete();
                }}
              >
                Yes
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-gray rounded  "
                onClick={() => {
                  handleCancelDelete();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteSucess && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 h-56 w-3/6 border border-primary shadow-red rounded-md shadow-md backdrop-filter backdrop-blur-lg">
            <p className="text-2xl flex justify-center mt-14">
              Business Account Deleted successfully
            </p>

            <div className="flex justify-center items-center h-14 ">
              <img
                src={check}
                alt="checkout"
                className="h-full w-auto object-cover"
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <button
          className="flex p-2 font-bold mt-4"
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsArrowLeft className="mr-2" size={23} />
          Back
        </button>
      </div>
    </div>
  );
};

export default DashboardPage;
