import api from "../api";

const baseURL = "/api/entrevoret";

export const postEnquiry = (name, email, mobile, enquiry, file) => {
  console.log("test", name, email, mobile, enquiry, file);
  const formData = new FormData();

  // Append the file
  formData.append("file", file);

  // Append other fields
  formData.append("name", name);
  formData.append("email", email);
  formData.append("mobile", mobile);
  formData.append("enquiry", enquiry);
  console.log("file", formData.size);
  return api.post(`${baseURL}/enquiry`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
