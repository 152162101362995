import React, { useContext, useEffect, useState } from "react";
import {
  FaArrowUp,
  FaBriefcase,
  FaCalendarAlt,
  FaQuestion,
  FaUserCircle,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFileCircleQuestion } from "react-icons/fa6";
import UpdateNews from "./UpdateNews";
import profile from "../../assets/Male User.png";
import businesspic from "../../assets/Business Network.png";
import question from "../../assets/Questions.png";
import upload from "../../assets/Upload.png";
import { Link, useNavigate } from "react-router-dom";
import { IoIosLink } from "react-icons/io";
import { IoArrowForward } from "react-icons/io5";
import { dashboard, liveStream } from "../../services/admin";
import { me } from "../../services/user";
import UserContext from "../context provider/UserContext";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState("");
  const [embbedUrl, setEmbbedUrl] = useState("");
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    me()
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log("ERR", error);
        navigate("/Login");
      });
  }, []);

  useEffect(() => {
    if (user) {
      if (startDate !== null) {
        dashboard(startDate).then((res) => {
          console.log("lkdsafjlsa", res.data);
          setDashboardData(res.data);
        });
      } else {
        dashboard().then((res) => {
          console.log("lkdsafjlsa", res.data);
          setDashboardData(res.data);
        });
      }
    }
  }, [startDate]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const data = [
    {
      image: profile,
      count: dashboardData.user,
      text: "Number of users",
    },
    {
      link: "/dashboard",
      image: businesspic,
      count: dashboardData.business,
      text: "Number of business users",
    },
    {
      image: question,
      count: dashboardData.jobsApplied,
      text: "Number of jobs applied",
    },

    {
      image: upload,
      count: dashboardData.enquiries,
      text: "Number of enquires",
    },
  ];

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value.startsWith("<iframe ")) {
      setEmbbedUrl(e.target.value);
    } else {
      alert("enter the embed url");
    }
  };

  const extractParams = (url) => {
    const srcRegex = /src="([^"]+)"/;
    const match = url.match(srcRegex);

    //for urlId
    const srcIndex = url.indexOf("?");
    const src = srcIndex !== -1 ? url.slice(0, srcIndex) : url;
    const id = src.split("/");
    // If a match is found, return the captured src value
    if (match && match.length > 1) {
      return {
        url: match[1],
        urlId: id[4],
      };
    } else {
      return ""; // Return an empty string if no src attribute is found
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { url, urlId } = extractParams(embbedUrl);
    liveStream(embbedUrl, url, urlId).then(() => {
      setEmbbedUrl("");
    });
  };

  return (
    <div className="md:px-4 max-w-screen-2xl  w-4/6 py-10 mx-auto">
      <div className="mb-5   flex justify-between">
        <h1 className="text-2xl font-bold  mb-2">Dashboard</h1>
        <div className="text-white">
          {isOpen === false ? (
            <button
              className="bg-primary p-2  hover:bg-black space-x-2 flex items-center"
              onClick={handleButtonClick}
            >
              <span>Filter by date</span>
              <FaCalendarAlt size={20} />
            </button>
          ) : (
            <>
              <button
                className="bg-primary  hover:bg-black p-2 space-x-2 ml-auto  flex items-center"
                onClick={handleButtonClick}
              >
                <span>Filter by date</span>
                <FaCalendarAlt size={20} />
              </button>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select date"
                className="rounded mt-2 text-black text-center w-full"
              />
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {data.map((item, index) => (
          <>
            {item.text === "Number of business users" ? (
              <Link to={item.link}>
                <div key={index} className="w-full flex flex-col items-center">
                  <button className="bg-primary w-full h-24 md:h-44 pl-4 text-white">
                    <h1 className="mb-2 md:mb-8 text-lg md:text-xl">
                      {item.count}
                    </h1>
                    <div className="flex justify-center items-center h-12">
                      <img
                        src={item.image}
                        alt="profile"
                        className="h-full w-auto object-cover"
                      />
                    </div>
                  </button>
                  <p className="text-center md:text-left">{item.text}</p>
                </div>
              </Link>
            ) : (
              <div key={index} className="w-full flex flex-col items-center">
                <button className="bg-primary w-full h-24 md:h-44 pl-4 text-white">
                  <h1 className="mb-2 md:mb-8 text-lg md:text-xl">
                    {item.count}
                  </h1>
                  <div className="flex justify-center items-center h-12">
                    <img
                      src={item.image}
                      alt="profile"
                      className="h-full w-auto object-cover"
                    />
                  </div>
                </button>
                <p className="text-center md:text-left">{item.text}</p>
              </div>
            )}
          </>
        ))}
      </div>
      <div className="mt-4">
        <h1 className="text-2xl font-bold ">Stream now </h1>
        <div className="relative">
          <IoIosLink className="absolute mt-5 ml-2 " size={23} />
          <input
            type="text"
            placeholder="Share the live streaming URL here to stream it to users"
            className=" outline-none  border border-primary py-4 w-full cursor-text text-sm  p-10 mt-2"
            value={embbedUrl}
            onChange={(e) => handleInputChange(e)}
          />

          <IoArrowForward
            className="absolute  text-primary cursor-pointer top-2 right-0 mt-2 p-2"
            size={34}
            onClick={(e) => {
              handleSubmit(e);
            }}
          />
        </div>
      </div>
      <UpdateNews />
    </div>
  );
};

export default Dashboard;
