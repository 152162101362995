import logo from "./logo.svg";
import "./App.css";
import { HelmetProvider, Helmet } from "react-helmet-async";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Main from "./components/main";
import Navbar from "./components/footer&nav/NavFotter";
import SignupFlow1 from "./components/Singnup/SignupFlow1";
import SignupPage from "./components/Singnup/SignupPage";
import LoginFlow1 from "./components/Login/LoginFlow1";
import LoginPage from "./components/Login/LoginPage";
import ForgotpwFlow1 from "./components/Login/ForgotPassword/ForgotpwFlow1";
import ForgotpwFlow2 from "./components/Login/ForgotPassword/ForgotpwFlow2";
import Dashdoard from "./components/Admin/Dashdoard";
import UpdateNews from "./components/Admin/UpdateNews";
import Livestream from "./components/Livestream";
import Businesspage from "./components/business/Businesspage";
import UserPageview from "./components/UserPage/UserPageview";
import UpdateNewspage from "./components/Updatenews/UpdateNewspage";
import Dashbordpage from "./components/Admin/Dashbordpage";
import BusinessOnboard from "./components/Admin/BusinessOnboard";
import Profile from "./components/Profile/Profile";
import UserContext, {
  UserProvider,
} from "./components/context provider/UserContext";
import { useContext, useEffect } from "react";
import Flowotp from "./components/Login/ForgotPassword/Flowotp";
import NotFoundPage from "./NotFoundPage";
function App() {
  return (
    <HelmetProvider>
      <UserProvider>
        <BrowserRouter>
          {/* <Helmet>
            <title>One Mind Market</title>
            <meta
              name="description"
              content="Discover detailed business profiles and local shops on One Mind Market. Find restaurants, cafes, service providers, and more. Your guide to the best local businesses."
            />
            <meta property="og:title" content="One Mind Market" />
            <meta
              property="og:description"
              content="Discover detailed business profiles and local shops on One Mind Market. Find restaurants, cafes, service providers, and more. Your guide to the best local businesses."
            />
            <meta property="og:url" content="https://www.onemindmarket.in" />
            <meta property="og:type" content="website" />
            <link rel="canonical" href={`https://www.onemindmarket.in/`} />
          </Helmet> */}
          <Routes>
            {/* <Route path="/" element={<Main />} /> */}
            <Route path="/" element={<Navbar />}>
              <Route index element={<Main />} />
              <Route path="admin" element={<Dashdoard />} />
              <Route path="news" element={<UpdateNews />} />
              <Route path="Live" element={<Livestream />} />
              <Route path="business" element={<Businesspage />} />
              <Route
                path=":classification/:locatoin/:name/onemindmarket/:id"
                element={<UserPageview />}
              />
              <Route path="dashboard" element={<Dashbordpage />} />
              <Route path="BusinessOnboard" element={<BusinessOnboard />} />
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route path="Signupflow" element={<SignupFlow1 />} />
            <Route path="Signup" element={<SignupPage />} />
            <Route path="/Loginflow" element={<LoginFlow1 />} />
            <Route path="/Login" element={<LoginPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgotpasswordFlow1" element={<ForgotpwFlow1 />} />
            <Route path="/otp" element={<Flowotp />} />
            <Route path="/createPassword" element={<ForgotpwFlow2 />} />
            <Route path="/updatenews" element={<UpdateNewspage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </HelmetProvider>
  );
}

export default App;

const Logout = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");

    localStorage.removeItem("expiresAt");
    setUser("");
    navigate("/");
  }, [navigate]);

  return null;
};
