import React, { useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/One_Mind_Market_Black_Logo_with_text.png";
import logmob from "../../../assets/Variant_04.png";
import { changePassword } from "../../../services/user";

const ForgotpwFlow2 = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id || "";
  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRegister = () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    changePassword(id, password).then((res) => {
      navigate("/Login");
    });
    console.log("Resetting Password:", {
      password,
      confirmPassword,
    });
  };

  return (
    <div className="h-full max-w-screen-2xl min-h-screen mx-auto w-full flex flex-col md:flex-row">
      <span className="w-full md:w-1/2 bg-lightgray hidden md:inline  items-center justify-center p-6 xl:py-48">
        <img src={logo} alt="" className="w-1/2 md:w-1/2  xl:w-1/2 mx-auto" />
        <h1 className="flex justify-center font-inter mt-16">
          One City! One Classification! One Entrepreneur!
        </h1>
      </span>
      <span>
        <img src={logmob} alt="logo" className="md:hidden" />
      </span>

      <div className="w-full md:w-1/2 p-6">
        <div className="w-full flex justify-between p-4 md:p-8">
          <Link
            to="/forgotpasswordFlow1"
            className="flex font-semibold mb-4 md:mb-0"
          >
            <MdOutlineArrowBackIos className="mt-1" />
            Back
          </Link>
          <h1 className="text-center md:text-left">
            Don't have an account?{" "}
            <Link to="/Signupflow">
              <span className="text-primary font-semibold ml-1">Sign Up</span>
            </Link>
          </h1>
        </div>

        <div className="p-4 md:p-8">
          <h1 className="text-2xl font-bold mt-4 text-center md:text-left">
            Reset Password
          </h1>
          <div className="w-full">
            <form className="space-y-4">
              <p className="text-sm text-gray pt-3 text-center md:text-left">
                For the purpose of industry regulation, your details are
                required.
              </p>

              <div className="mb-3  relative">
                <label
                  htmlFor="createPassword"
                  className="block t font-medium "
                >
                  Create new password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="createPassword"
                  className="mt-1 p-2 border w-full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-3 text-gray cursor-pointer"
                  onClick={handlePasswordVisibilityToggle}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>

              <div className="mb-3 relative">
                <label htmlFor="confirmPassword" className="block font-medium ">
                  Confirm new password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  className="mt-1 p-2 border w-full"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />

                <button
                  type="button"
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 text-sm mt-3 text-gray cursor-pointer"
                  onClick={handleConfirmPasswordVisibilityToggle}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              </div>

              {passwordError && (
                <p className="text-red text-sm">{passwordError}</p>
              )}

              <button
                type="button"
                className="bg-primary  hover:bg-black text-white w-full py-3 mt-5"
                onClick={handleRegister}
              >
                Reset New Password
              </button>

              <h1 className="text-center  flex justify-center md:text-left mt-3">
                Just remember?{" "}
                <Link to="/Login">
                  <span className="text-primary  font-semibold pl-2">
                    Login
                  </span>
                </Link>
              </h1>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotpwFlow2;
