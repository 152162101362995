import api from "../api";

const baseURL = "/api/me";

export const me = () => {
  return api.get(baseURL);
};

export const updateProfile = (name, mobile, email, avatarKey, avatarUrl) => {
  return api.put(baseURL, {
    name,
    mobile,
    email,
    avatarKey,
    avatarUrl,
  });
};

export const removeAvatar = () => {
  return api.delete(`${baseURL}/avatar`);
};

export const updatePassword = (currentPassword, newPassword) => {
  console.log("hit");
  return api.put(`${baseURL}/changePassword`, {
    currentPassword,
    newPassword,
  });
};

export const deleteAccount = () => {
  return api.delete(`${baseURL}/accountDelete`);
};
